import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Input, FormControl, FormLabel, FormErrorMessage, HStack, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';
import { TagsInput } from "react-tag-input-component";
import PrimaryButton from './themeComponents/PrimaryButton';
import { getShortenedUrl, sendTestWhatsapp, sendTestEmail, sendTestEmailMulti, sendTestSms } from '../Services/api';
import { useToast } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { template } from 'lodash';
import * as _ from 'lodash';
import { FormikProvider } from 'formik';
function SendTestModal({ isOpen, onClose, channel, uuid, orgToken, templateData, selectedSmtpUuid, formik }) {
    const toast = useToast();
    const [email, setEmail] = useState("");
    const [phones, setPhones] = useState([]);
    const [error, setError] = useState(false);
    const [smptpType, setSmptpType] = useState("promotional")
    const checkExisting = () => {
        return
    }

    const smptpTypes = [
        {
            key: "Promotional",
            value: "promotional"
        },
        {
            key: "Transactional",
            value: "transactional"
        }
    ]


    let sendTestActions = {
        "Email": async function () {
            if (!!formik?.values?.orgList?.length) {
                let preparedData = {}

                let preparedTempData = []
                formik.values.orgList.forEach(async (orgToken) => {
                    if (window?.location?.href?.includes("all-banks")) {
                        preparedTempData.push({
                            orgToken: orgToken,
                            templateData: {
                                headerUuid: templateData[orgToken]?.header?.uuid || "",
                                bodyUuid: templateData[orgToken]?.body?.uuid || "",
                                footerUuid: templateData[orgToken]?.footer?.uuid || ""
                            },
                            email: email,
                            subject: formik.values.subjectLine,
                            selectedSmtpUuid: selectedSmtpUuid[orgToken],
                        })
                    }
                });
                preparedData = {
                    email: email,
                    // selectedSmtptype: smptpType,
                    // selectedTemplate: uuid,
                    // templateData : preparedTempData,
                    subject: formik.values.subjectLine,
                    selectedSmtpUuid: selectedSmtpUuid[orgToken],
                    // orgList: formik.values.orgList,
                    data: preparedTempData
                }
                let testEmail = await sendTestEmailMulti(preparedData, orgToken).catch((err) => toast({ title: 'Oops!', description: 'Something went wrong while sending test email', status: 'error' }
                )

                )
                if (!!testEmail.status == 200) {
                    toast({ title: 'Success', description: 'Test email sent successfully', status: 'success' })
                    onClose();

                }
            }
            else {
                let preparedData = {
                    email: email,
                    // selectedSmtptype: smptpType,
                    selectedTemplate: uuid,
                    // templateData : preparedTempData,
                    selectedSmtpUuid: selectedSmtpUuid,
                    broadcastType: 'BROADCAST_V2'
                }
                let testEmail = await sendTestEmail(preparedData, orgToken).catch((err) => toast({ title: 'Oops!', description: 'Something went wrong while sending test email', status: 'error' }))

                if (!!testEmail?.status == 200) {
                    toast({ title: 'Success', description: 'Test email sent successfully', status: 'success' })
                    onClose();
                }
            }

        },
        "Whatsapp": async function () {

            let numbers = []
            let mappedUserPhones = phones.map((num, i) => {
                return numbers[i] = { phone: num }
            })
            let data = []
            if(!!formik?.values?.orgList.length){
                formik.values.orgList.forEach((org)=>{
                    data.push({
                        orgToken : org,
                        mappedUserPhones,
                        selectedTemp: uuid || templateData[orgToken]?.uuid        
                    })
                })
            }
            let preparedData = {
               data
            }
            if(!formik?.values?.orgList?.length){
                preparedData = {
                    mappedUserPhones,
                    selectedTemp: uuid || templateData[orgToken]?.uuid        
                }
            }

            let testWhatsapp = await sendTestWhatsapp(preparedData, orgToken).catch((err) => toast({ title: 'Oops!', description: 'Something went wrong while sending test whatsapp', status: 'error' }))
            if (!!testWhatsapp) {
                toast({ title: 'Success', description: 'Test whatsapp sent successfully', status: 'success' })
            }
        },
        "Sms": async function () {
            
            let numbers = []
            let destination = phones.map((num, i) => {
                return numbers[i] = { phone: num }
            })
            let preparedTempData = []
            if (!!formik?.values?.orgList?.length) {
                let preparedData = {}

                // let preparedTempData = []
                formik.values.orgList.forEach(async (orgToken) => {
                    if (window?.location?.href?.includes("all-banks")) {
                        preparedTempData.push({
                            orgToken: orgToken,
                            selectedTemp: uuid || templateData[orgToken]?.uuid,
                            destination,
                            approvedTemplateId:"",
                        })
                    }
                });
            }
            let preparedData = {
                data : preparedTempData,
            }
            if(!formik.values.orgList?.length) {
                preparedData = {
                    selectedTemp: uuid || templateData[orgToken]?.uuid,
                            destination,
                }
            }

            let testSms = await sendTestSms(preparedData, orgToken).catch((err) => toast({ title: 'Oops!', description: 'Something went wrong while sending test sms', status: 'error' }))
            if (!!testSms) {
                toast({ title: 'Success', description: 'Test sms sent successfully', status: 'success' })
            }
        }
    }
    const sendTestCommunication = () => {
        
        !error &&
            sendTestActions[_.capitalize(channel)]();
    }
    const validateTag = (value) => {
        let re = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
        if (re.test(value)) {
            setError(false)
            return re.test(value)
        } else {
            setError(true)
            return re.test(value)
        }
    }
    const validateEmail = (value) => {
        let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (re.test(value)) {
            setError(false)
            return re.test(value)
        } else {
            setError(true)
            return re.test(value)
        }
    }

    return (
        <Modal isOpen={isOpen} size={'xl'} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text>Send Test {channel}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt={0}>
                    <HStack spacing={4} w={'100%'} >
                        {
                            channel.toLowerCase() == 'email' &&
                            <>
                                <VStack w={'100%'} >
                                    <Text></Text>
                                    <Input value={email} placeholder='Enter email' onChange={(e) => setEmail(e.target.value)} onKeyUp={(e) => validateEmail(e.target.value)} />
                                    {/* <Select value={smptpType} onChange={(e)=>setSmptpType(e.target.value)} >
                                    {
                                        smptpTypes.map((e) => (
                                            <option value={e.value} >
                                                {e.key}
                                            </option>
                                        ))
                                        
                                        }
                                </Select> */}
                                </VStack>
                            </>
                        }
                        {
                            channel.toLowerCase() !== 'email' &&
                            <TagsInput
                                style={{ width: "100%" }}
                                value={phones}
                                onChange={setPhones}
                                name="phone"
                                placeHolder="Enter Phone(s)"
                                onExisting={checkExisting}
                                beforeAddValidate={validateTag}
                            />
                        }

                    </HStack>
                    {error && <Text size={'sm'} color={'red'} >Enter a valid {channel == 'Email' ? "email" : "number"}</Text>}
                    <PrimaryButton label={'Send'} mt={2} float={'right'} onClick={sendTestCommunication} />
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default SendTestModal;
