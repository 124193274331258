import { Text, useToast } from '@chakra-ui/react'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { getTokenFromLoacalStorage } from '../_helpers/helpers'


function PrivateRoutes({ Component }) {
    const authData = useSelector(state => state.auth)
    const isAllBanks = getTokenFromLoacalStorage()?.user_data?.extraData?.isAllBanks || getTokenFromLoacalStorage()?.user_data?.extraData?.all_features_user
    let location = useLocation();
    const toast = useToast()
    if (!!authData.isLoading) {
        return <Text fontSize={'3xl'}>Loading....</Text>
    }
    if (!authData.isAuthenticated) {
        return <Navigate to={'/auth'}></Navigate>
    }
    if (!!_.isEmpty(authData.selectedOrg)) {
        return <Navigate to={'/organization'}></Navigate>
    }
    if (location.pathname.includes("all-banks") && !isAllBanks) {    
        toast({title: 'Oops!', description: "You don't have access to this feature.", status: 'error'});
        return <Navigate to={'/'} />
    }
    return (
        <Component />
    )
}

export default PrivateRoutes
