import React from 'react'
import store from '../../store/store'

export default function Products() {
    const token = JSON.parse(localStorage.getItem('selectedOrg')).token 
    let localStorageData = localStorage.getItem("token") || '{}';
    let tokenData = JSON.parse(localStorageData);
    let selectedOrg = localStorage.getItem("selectedOrg");
    // Ensure the token is URL-encoded for safe inclusion in the query string
    //const encodedToken = encodeURIComponent(bearerToken);
    const orgToken = store.getState().auth?.selectedOrg?.token;
  return (
      <iframe
        src={`${process.env.REACT_APP_IFRAME_AUTO_LOGIN_ENDPOINT}/products/?orgToken=${orgToken}&token=${tokenData.jwt}`}
        height={'100%'}
        width={'100%'}
        title="Opt-In Link"
      ></iframe>
    );
}
