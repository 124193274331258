import React, { useState, useEffect, Fragment } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  Input,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';
import { getSegmentationProps } from '../../Services/api';
import { prepareSegmentationProps } from './SegmentDataHelper';

const FilterProductsModal = ({ isOpen, onClose, onApplyFilters, orgId }) => {
  const [filters, setFilters] = useState([]); // Array to manage filter criteria
  const [segmentationProps, setSegmentationProps] = useState({});

  useEffect(() => {
    const fetchSegmentationProps = async () => {
      try {
        const resp = await getSegmentationProps(orgId);
        if (resp?.data?.targets && resp?.data?.targets.length > 0) {
          setSegmentationProps(prepareSegmentationProps(resp.data) || {});
        } else {
          console.warn('No targets found in response');
        }
      } catch (error) {
        console.error('Error fetching segmentation props:', error);
      }
    };

    fetchSegmentationProps();
  }, [orgId]);

  const handleAddFilter = () => {
    setFilters((prevFilters) => [
      ...prevFilters,
      {
        property: '',
        operator: '',
        values: [{ raw: '' }],
      },
    ]);
  };

  const handleFilterChange = (index, key, value) => {
    
    setFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[index][key] = value;
      updatedFilters[index]["criteria_type"] = "query";
      if (key === 'property') {
        updatedFilters[index].property_name =
          segmentationProps.props?.product?.[value]?.name || '';
        updatedFilters[index].operator = '';
        updatedFilters[index].operator_name = '';
        updatedFilters[index].data_type =
        segmentationProps.props?.product?.[value]['dataType']  
      } else if (key === 'operator') {
        updatedFilters[index].operator_name =
          segmentationProps.operators?.product?.[updatedFilters[index].property]?.find(
            (op) => op.value === value
          )?.key || '';
      }
  
      return updatedFilters;
    });
  };

  const handleValueChange = (filterIndex, valueIndex, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[filterIndex].values[valueIndex].raw = value;
      return updatedFilters;
    });
  };

  const handleRemoveFilter = (index) => {
    setFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
  };

  const handleAddValue = (filterIndex) => {
    setFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[filterIndex].values.push({ raw: '' });
      return updatedFilters;
    });
  };

  const handleRemoveValue = (filterIndex, valueIndex) => {
    setFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[filterIndex].values = updatedFilters[filterIndex].values.filter(
        (_, i) => i !== valueIndex
      );
      return updatedFilters;
    });
  };

  const applyFilters = () => {
    onApplyFilters(filters);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} size={'xl'} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Filter Products</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {filters.map((filter, index) => (
            <Flex key={index} gap={3} mt={3} alignItems={'start'} w={'100%'}>
              <Select
                maxW={'350px'}
                placeholder="Select Property"
                value={filter.property}
                onChange={(e) => handleFilterChange(index, 'property', e.target.value)}
              >
                {Object.keys(segmentationProps["props"]['product']).map((prop) => (
                  <option key={prop} value={prop}>
                    {segmentationProps["props"]['product'][prop].name}
                  </option>
                ))}
              </Select>
              {/* {JSON.stringify(segmentationProps?.operators["product"][filter.property])} */}
              {filter.property && (
                <Fragment>
                  <Select
                    maxW={'350px'}
                    placeholder="Select Operator"
                    value={filter.operator}
                    onChange={(e) => handleFilterChange(index, 'operator', e.target.value)}
                  >
                    
                    {segmentationProps?.operators["product"][filter.property]?.map((operator) => (
                      <option key={operator.value} value={operator.value}>
                        {operator.key}
                      </option>
                    ))}
                  </Select>

                  {filter.values.map((value, valueIndex) => (
                    <Flex key={valueIndex} gap={2} mb={2}>
                      <Input
                        type={segmentationProps[filter.property]?.inputType || 'text'}
                        value={value.raw}
                        onChange={(e) =>
                          handleValueChange(index, valueIndex, e.target.value)
                        }
                      />
                      <Button onClick={() => handleAddValue(index)}>OR</Button>
                      {valueIndex > 0 && (
                        <Button onClick={() => handleRemoveValue(index, valueIndex)}>
                          Remove
                        </Button>
                      )}
                    </Flex>
                  ))}
                </Fragment>
              )}

              <Icon
                as={FaTrash}
                cursor={'pointer'}
                onClick={() => handleRemoveFilter(index)}
              />
            </Flex>
          ))}

          <Button mt={3} onClick={handleAddFilter}>
            Add Filter
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={applyFilters}>
            Apply Filters
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FilterProductsModal;
