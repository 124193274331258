import React from 'react';
import store from '../../store/store'

export default function OptInLink() {
  let localStorageData = localStorage.getItem("token") || '{}';
  let tokenData = JSON.parse(localStorageData);
      // let decodedToken = extractUserDataFromJWT(JSON.parse(token) )
  let selectedOrg = localStorage.getItem("selectedOrg");
  // Ensure the token is URL-encoded for safe inclusion in the query string
  //const encodedToken = encodeURIComponent(bearerToken);
  const orgToken = store.getState().auth?.selectedOrg?.token;
  let path = `/exitintent/#/exit-intent-overview`
  path = encodeURIComponent(path);
return (
    <iframe
      src={`${process.env.REACT_APP_IFRAME_AUTO_LOGIN_ENDPOINT}/exitintent/?orgToken=${orgToken}&token=${tokenData.jwt}&path=${path}`}
      height={'100%'}
      width={'100%'}
      title="Opt-In Link"
    ></iframe>
  );
}
