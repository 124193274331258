import React from 'react';
import { IoCartOutline } from "react-icons/io5";
import { Box, Flex, Text, useToken } from '@chakra-ui/react';
import { AiOutlineStock } from "react-icons/ai";
import { FaPercentage, FaRegMoneyBillAlt, FaWallet } from "react-icons/fa";
import { IoMdPricetag } from "react-icons/io";
import { OvalSpinner } from '../../components/Loaders';


const stats = [
  { icon: <IoCartOutline size={24} />, label: 'Orders' },
  { icon: <AiOutlineStock size={24} />, label: 'Gross Revenue' },
  { icon: <FaPercentage size={24} />, label: 'Total Discounts' },
  // { icon: <FaRegMoneyBillAlt size={24} />, label: 'Returns' },
  // { icon: <IoMdPricetag size={24} />, label: 'Net Sales' },
  // { icon: <FaWallet size={24} />, label: 'Net Revenue' },
];


const Orderwidget = ({ icon, label, value }) => {
  return (
    <Box p={4} borderWidth={1} borderRadius="md" boxShadow="md" flex="1" minWidth="200px">
      <Flex align="center" gap={4}>
        {icon}
        <Box>
          <Flex direction="column" align="flex-start">
            <Text fontSize="lg" fontWeight="bold">{label}</Text>
            <Text color="gray.600">{value}</Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};


const OrderStats = ({ orderData, loading }) => {
  console.log(orderData, "orderData in stats");
  const [stripTable, primary, heading] = useToken('colors', ['stripTable', 'primary.main', 'heading']);


  return (
    <Box p={4} >
      <Flex direction="row" wrap="wrap" gap={6} justify="space-between">
        {stats.map((stat, index) => (
          <Orderwidget
            key={index}
            icon={stat.icon}
            label={stat.label}
            value={loading ? (
              <OvalSpinner height={20} width={20} color={primary} />
            ) : (
              orderData[stat.label] || "N/A"
            )}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default OrderStats;
