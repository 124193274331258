import React, { useEffect, useState } from "react";
import { Checkbox, CheckboxGroup, VStack, Button, Box, useToast, Text, SimpleGrid } from "@chakra-ui/react";
import axiosInstance from "../../Services/axiosInstance";

const JavascriptFeatures = () => {
  const [checkedItems, setCheckedItems] = useState([]); // Stores selected options
  const toast = useToast();

  // Options to display as checkboxes
  const options = [
    "LiveActivities",
    "BroadcastCampaign",
    "EventsTracking",
    "BrowserPushNotification",
    "SmartBlock",
    "ExitIntent",
    "OnsitePushNotification",
    "Opt-In On Load",
    "Exit Intent CSS",
    "SPA",
    "Force HTTP Method of Opt-In",
    "IS BIGCOMMERCE",
    "IS SMS CONTACTS MAP",
    "APPEND MKT UTM RESTRICT",
    "HIDE OPT-IN BRANDING",
  ];

  const mapResponseKeysToOptions = {
    LiveActivities: "LiveActivities",
    BroadcastCampaign: "BroadcastCampaign",
    EventsTracking: "EventsTracking",
    BrowserPushNotification: "BrowserPushNotification",
    SmartBlock: "SmartBlock",
    ExitIntent: "ExitIntent",
    OnsitePushNotification: "OnsitePushNotification",
    opt_in_onload: "Opt-In On Load",
    exit_intent_css: "Exit Intent CSS",
    is_spa: "SPA",
    force_http: "Force HTTP Method of Opt-In",
    is_bigcommerce: "IS BIGCOMMERCE",
    is_sms_contacts_map: "IS SMS CONTACTS MAP",
    appendMktUtmRestrict: "APPEND MKT UTM RESTRICT",
    hide_optin_branding: "HIDE OPT-IN BRANDING",
  };

  // Fetch initial JS features
  const getJSFeatures = async () => {
    try {
      const res = await axiosInstance.get("/admin/jsfeatures");
      const responseData = res.data;

      // Pre-select checkboxes with true values
      const preSelectedItems = Object.keys(responseData)
        .filter((key) => responseData[key]) // Filter only true values
        .map((key) => mapResponseKeysToOptions[key]); // Map keys to display names

      setCheckedItems(preSelectedItems); // Update state with pre-selected options
    } catch (error) {
      console.error("Error fetching JS features:", error);
      toast({
        title: "Error",
        description: "Failed to load JavaScript features.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCheckboxChange = (checkedValues) => {
    setCheckedItems(checkedValues);
  };

  const handleSubmit = () => {
    let formData = new FormData();

    checkedItems.forEach((item) => {
      // Find the corresponding key in the response for the checkbox value
      const key = Object.keys(mapResponseKeysToOptions).find(
        (key) => mapResponseKeysToOptions[key] === item
      );
      if (key) {
        formData.append(key, true);
      }
    });

    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    axiosInstance
      .post("/admin/jsfeatures", formData)
      .then((response) => {
        toast({
          title: "Success",
          description: "Options updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: "There was an error updating the options.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    getJSFeatures();
  }, []);

  return (
    <>
      <Box my={2}>
        <Text fontSize={"1.5vw"}>Javascript Features</Text>
        <Text fontSize={"1vw"}>
          Select the features that you want to enable in Javascript SDK
        </Text>
      </Box>
      <Box bg={"#fff"} p={"3rem"} borderRadius={"8px"}>
        <SimpleGrid columns={2} spacing={5}>
          {options.map((option, index) => (
            <Checkbox key={index} value={option} isChecked={checkedItems.includes(option)} onChange={(e) => handleCheckboxChange(e.target.checked ? [...checkedItems, option] : checkedItems.filter(item => item !== option))}>
              {option}
            </Checkbox>
          ))}
        </SimpleGrid>
        <Box mt={8} textAlign="center">
          <Button onClick={handleSubmit} colorScheme="blue">
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default JavascriptFeatures;
