import axios from 'axios';
import axiosInstance from '../../Services/axiosInstance';
const orgId = !!localStorage?.selectedOrg && JSON.parse(localStorage?.selectedOrg)?.token; 
export const ProductService = {
  getProductTableHeaders: () => [
    'Product', 'Product Id', 'Product Type', 'Published At', 'Updated At', 'Total Orders', 'Gross Profit', 'Handle', 'Source'
  ],

  getProductDetails: async (orgId, productId) => {
    const url = `/analytics/rest/v1/run_query/org/${orgId}/products/${productId}`;
    const response = await axiosInstance.post(url,{});
    return response.data?.data || null;
  },

  getVariantDetails: async (orgId, productId, variantId) => {
    const url = `/analytics/rest/v1/run_query/org/${orgId}/products/${productId}/variants/${variantId}`;
    const response = await axios.post(url);
    return response.data?.data || null;
  },
  prepareDataJson : (segmentationJson, externalCriteria, fields) => {
    
    let preparedData = {
      "target": "products",
      "org_id": orgId,
      "criteria": {
          "criteria_type": "intermediate",
          "criteria_operation": "and",
          "criteria": !!segmentationJson.length && segmentationJson
      }
  }
  if(!segmentationJson[0]?.property){
    preparedData['criteria'] = {
      "criteria_type": "intermediate",
      "criteria_operation": "and",
      "criteria": []
  }
  }
    return preparedData;
  },
  

  
  getTargetList : async (segmentationJson, orgId, target, pageNum, perPage, order, orderBy, fromPagination) => {
    let url = '';
  
    if (target === 'ManagedJobs') {
      url = `/analytics/rest/v1/run_query/job-scheduler/jobs/page/${pageNum}/page_size/${perPage}?order=${order}&orderBy=${orderBy}`;
      try {
        const response = await axiosInstance.post(url, segmentationJson );
        return response.data;  
      } catch (error) {
        console.error('Error fetching ManagedJobs:', error);
        throw new Error('Error fetching ManagedJobs: ' + error.message);
      }
    } else {
      let data = segmentationJson;

      if (target === 'orders' && fromPagination) {
        if (!data['flag']) {
          data['flag'] = 'paginate';
        }
      }
  
      url = `/analytics/rest/v1/run_query/org/${orgId}/segment/products/page/${pageNum - 1}/page_size/${perPage}`;
  
      try {
        const response = await axiosInstance.post(url, data);
        return response.data; 
      } catch (error) {
        console.error('Error fetching target list:', error);
        throw new Error('Error fetching target list: ' + error.message);
      }
    }
  },
  getSegmentationProps: () => ({
    amount_refunded: { name: 'Amount Refunded', value: 'amount_refunded', dataType: 'decimal', inputType: 'number' },
    cost_of_goods_sold: { name: 'Cost of Goods Sold', value: 'cost_of_goods_sold', dataType: 'decimal', inputType: 'number' },
    created_at: { name: 'Creation Date', value: 'created_at', dataType: 'datetime', inputType: 'date' },
    // More properties...
  }),

  getProductBuyerSegemntationJson: () => ({
    "order_start_date": new Date(null).toISOString(),
    "order_end_date": new Date().toISOString(),
    'product_criteria': {
      "criteria_type": "intermediate",
      "criteria_operation": "and",
      "criteria": []
    }
  }),

  getProductPerformance: async (productId, dateRange) => {
    // const date = {
    //   start_date: new Date(dateRange?.date?.startDate).toISOString(),
    //   end_date: new Date(dateRange?.date?.endDate).toISOString()
    // };
    const url = `/analytics/rest/v1/run_query/org/${orgId}/performance/product/${productId}`;
    const response = await axios.post(url);
    return response.data;
  },

  getVariantUtmPerformance: async (variantId, dateRange) => {
    const date = {
      start_date: new Date(dateRange.date.startDate).toISOString(),
      end_date: new Date(dateRange.date.endDate).toISOString()
    };
    const url = `/analytics/rest/v1/run_query/org/${orgId}/performance/variant/${variantId}?${new URLSearchParams(date).toString()}`;
    const response = await axios.post(url);
    return response.data?.data || null;
  }
};
