import { Modal, Box, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'

function TemplatePreviewModal({ isOpen, onClose, tempBody, tempFooter, tempHeader }) {
    const [combinedTemp, setCombinedTemp] = useState("");

    useEffect(() => {
        
        setCombinedTemp(`${tempHeader || ""}${tempBody || ""}${tempFooter || ""}`);
    }, [tempHeader, tempBody, tempFooter]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'80%'} scrollBehavior='inside'>
            <ModalOverlay bg={'#fff'} />
            <ModalContent w={'auto'}>
                <ModalHeader fontSize={'sm'}>Template Preview</ModalHeader>
                <ModalCloseButton onClick={onClose} />
                <ModalBody w={'100%'}>
                    <Box 
                        dangerouslySetInnerHTML={{ 
                            __html: combinedTemp || '<b>No Preview Available</b>' 
                        }} 
                        w={'100%'}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default TemplatePreviewModal;
