import axiosInstance from "./axiosInstance";
import moment from "moment";
import tzmoment from "moment-timezone";
import store from '../store/store'
import { createQueryDataForCampaign, prepareABPublishData, preparePublishData, preparePublishDataAllBanks } from "../_helpers/data_helper";
import _, { escape } from "lodash"
import axios from "axios";
import Swal from "sweetalert2";
export const ENDPOINTS = {
    dashboardWidget: '/dashboard/v6/getrevenuedata',
    campaignRevenue: '/dashboard/v6/bestperformingcampaigns',
    events: '/dashboard/v6/geteventsdata',
    subscribers: '/dashboard/v6/getsubscribersstats',
    draftCampaign: '/broadcast/v2/campaign',
    singleCampaign: '/broadcast/v2/campaign/',
    getCampaigns: '/broadcast/campaigns',
    getCampaignsAllBanks: '/broadcast/v3/campaigns',
    subscribersCount: '/broadcast/totalsubscriberscount/',
    uploadedFiles: '/rest/v1/broadcast/getuploadedfiles/',
    uploadFile: '/rest/v1/uploadfile/wzbroadcast/',
    csvHeaders: '/dashboard/v6/broadcast/existingcsvheaders/',
    saveValidationData: '/dashboard/v6/broadcast/csv/validation/save/',
    templates: {
        email: '/rest/v1/email/alltemplate',
        push: '/rest/v1/push/alltemplate',
        whatsapp: `/whatsapp/templates`,
        sms : `/rest/v1/sms/alltemplate`
    },
    publishCampaign: '/broadcast/v2/start',
    publishCampaignAll: '/broadcast/v3/start',
    eventNameJson: 'rest/v1/org/events',
    saveRule: '/rest/v1/eventserver/rule',
    getFlows: '/rest/v1/eventserver/rule',
    toggleRule: '/eventserver/togglerulestatus/',
    deleteRule: '/rest/v1/eventserver/rule/',
    fetchRule: '/rest/v1/eventserver/rule/',
    editRecipeDetails: '/dashboard/v6/automation/recipes/edit/',
    stripoCredentials: '/rest/v1/stripocredentials',
    saveEmailTemplate: '/rest/v1/emailtemplate/templates',
    getEmailTemplateList: '/emailtemplate/templates',
    fetchEmailSavedTemplate: '/emailtemplate/templates/',
    deleteEmailTemplate: '/emailtemplate/templates/',
    saveSmsTemplate: '/sms/template',
    getSmsTemplateList: '/sms/template',
    getPushTemplateList : '/rest/v1/push/template',
    deleteSmsTemplate: '/sms/template/',
    saveWhatsapptemplate: '/whatsapp/template',
    getWhatsappTemplateList: '/whatsapp/templates',
    getWhatsappTemplateDetails: '/whatsapp/template',

    applyWhatsapptemplate: '/whatsapp/template?apply=true',
    segmentationProps: '/rest/v1/segmentation/getprops',
    predefinedSegmentCount: '/analytics/rest/v1/run_query/org',
    applyWhatsapptemplate: '/whatsapp/template?apply=true',

    getImageGallery: '/rest/v1/account/imagegallery',
    uploadImage: "/rest/v1/account/imagegallery/upload",

    saveNudgeTemplate: '/rest/v1/onsitepush/template?uuid=',
    getNudgeTemplateList: '/rest/v1/onsitepush/template',
    fetchNudgeTemplate: '/rest/v1/onsitepush/template/',
    deleteNudgeTemplate: '/rest/v1/nudge/template/',
    smsGatwayList: "/sms/gateways",
    sendTestWhatsapp : "/whatsapp/sendTestMessage",
    sendTestEmail : "/broadcast/v2/sendTest/email",
    revenueBreakdown : '/revenue/breakdown',
    revenueStats:'/dashboard/v6/revenue-bar-chart/',
    emailConfigurations : '/smtpDetails',
    whatsAppChannelRegister:`/whatsapp/register`,
    sendAbTest : '/broadcast/v3/saveAbTestCampaigns',
    draftCampaignAllbanks: '/broadcast/v3/campaigns',
    sendTestWhatsapp: "/whatsapp/sendTestMessage",
    sendTestSms: "/broadcast/v2/sendTest/sms",
    sendTestEmail: "/broadcast/v2/sendTest/email",
    sendTestEmailMulti: "/broadcast/v2/sendTest/email",
    revenueBreakdown: '/revenue/breakdown',
    revenueStats: '/dashboard/v6/revenue-bar-chart/',
    emailConfigurations: '/smtpDetails',
    sendAbTest: '/broadcast/v3/saveAbTestCampaigns',
    publishABCampaign: '/broadcast/v3/start',
    MultipleScheduleCampaign: '/broadcast/v3/start',
    getCountsFromQuery: '/analytics/rest/v1/run_query/org/',
    getCountsFromQueryMulti: '/analytics/rest/v1/run_query_multi',
    getFacebookLoginUrl: '/fb/login/settings/mkt?restful=true&channel=facebook',
    saveDraftAllBanks : '/broadcast/v3/draft',
    getAIBlockFormElements: '/ai-blocks/v1/formelements',
    getBlockMarkup: '/ai-blocks/v1/images',
    saveOrUpdateBlock: '/ai-blocks/v1/save',
    getBlockCode: '/ai-blocks/v1/blockcode',
    getAllBlocks: '/ai-blocks/v1/blocks',
    postApprovalAllBanks : '/broadcast/v3/approval-status'
}

const getDateQuery = (date) => {
    return { startDate: new Date(date.startDate).toISOString(), endDate: new Date(date.endDate).toISOString() }
}

export function getWidgetsData(token, date) {
    console.log('daterange', date)
    const url = ENDPOINTS.dashboardWidget;
    return axiosInstance.get(url, { params: { orgToken: token, ...getDateQuery(date) } });
}

export function getCampaignRevenue(token, date) {
    const url = ENDPOINTS.campaignRevenue;
    return axiosInstance.get(url, { params: { orgToken: token, ...getDateQuery(date) } })
}
export function getRevenueBreakdown(token, date) {
    const url = ENDPOINTS.revenueBreakdown;
    return axiosInstance.get(url, { params: { orgToken: token, ...getDateQuery(date) } })
}
export function getRevenueStats(token, date) {
    const url = ENDPOINTS.revenueStats;
    return axiosInstance.get(url, { params: { orgToken: token, ...getDateQuery(date) } })
}

export function getEvents(token, date) {
    const url = ENDPOINTS.events;
    return axiosInstance.get(url, { params: { orgToken: token, ...getDateQuery(date) } })
}

export function getSubscribersCount(token, date) {
    const url = ENDPOINTS.subscribers;
    return axiosInstance.get(url)
}

export function draftCampaign(token, data) {
    const url = ENDPOINTS.draftCampaign;
    return axiosInstance.post(url, data, { params: { orgToken: token } });
}
export function draftCampaignAllbanks(token, data) {
    const url = ENDPOINTS.draftCampaignAllbanks;
    return axiosInstance.post(url, data, { params: { orgToken: token } });
}
export function sendAbTest(token, data) {
    const url = ENDPOINTS.sendAbTest;
    return axiosInstance.post(url, data, { params: { orgToken: token } });
}
export  function saveMultipleSchedule(token,data){
    const url=`/broadcast/v3/saveAbTestCampaigns`
    return axiosInstance.post(url, data, { params: { orgToken: token } });

}

export function getCampaigns(token, filters, dateRange, currentPage) {
    
    const url = ENDPOINTS.getCampaigns;
    return axiosInstance.get(url, { params: { orgToken: token, page: currentPage - 1, ...createQueryDataForCampaign(filters, dateRange) } })
}
export function getCampaignsAllBanks(token, filters, dateRange, currentPage) {

    const url = ENDPOINTS.getCampaignsAllBanks;
    return axiosInstance.get(url ,{ params: { orgToken: token, page: currentPage - 1, ...createQueryDataForCampaign(filters, dateRange) } })
}

export function getCampaign(token, uuid) {
    const url = ENDPOINTS.singleCampaign + uuid;
    return axiosInstance.get(url, { params: { orgToken: token } });
}

export function getTotalSubscribers(token, channel) {
    let url = ENDPOINTS.subscribersCount + channel;
    return axiosInstance.post(url, {}, { params: { orgToken: token } })
}

export function getSegmentList(token, segmentType, id) {
    let url = `/analytics/rest/v1/run_query/org/${id}/customer/segments/${segmentType}/list/name`;
    return axiosInstance.post(url, {}, { params: { orgToken: token } });
}

export function getSegmentListAllBanks( segmentType, ids) {
    let url = `/analytics/rest/v1/run_query_multi/customer/segments/${segmentType}/list/name`;
    return axiosInstance.post(url, {org_ids:ids});
    
}

export function getUploadedFiles(token, channel) {
    let url = ENDPOINTS.uploadedFiles + channel
    return axiosInstance.get(url, { params: { orgToken: token } })
}
export function getUploadedFilesAllBanks(token, tokenList,channel) {    
    
    let url = ENDPOINTS.uploadedFiles + channel + (Array.isArray(token) &&  ( "?org_tokens="+(JSON.stringify(token).replace("[","").replace("]", "").replaceAll('"',"")))) 
    return axiosInstance.get(url)
}

export function uploadCsvFile(token, channel, data) {
    let url = `${ENDPOINTS.uploadFile}${channel}?orgToken=${token}`;
    return axiosInstance.post(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
    })
}
const getTargetAudienceTypes = () => {
    return {
        SEND_TO_ALL: 'sendToAll', EXISTING_CSV: 'existingCsv', UPLOAD_CSV: 'uploadCsv', PREDEFINED_SEGMENTS: 'predefined', CUSTOM_SEGMENT: 'custom',
        RFM_SEGMENT: 'rfm', KLAVIYO: 'klaviyo', DAMAS_CRM: 'damasCrm'
    }
};

export function getPredefinedSegmentCount(orgId, token) {
    let url = `/analytics/rest/v1/run_query/org/${token}/customers/segments/rfm/customer_count`;
    return axiosInstance.post(url, {}, { params: { orgToken: token, start_date: moment().subtract(30, 'days').format('yyyy-MM-DD'), end_date: moment().format('yyyy-MM-DD') } })
}
let segmentCount = {
    predefined: [],
    custom: [],
    rfm: []
};
async function getSegmentCount(segmentType, segmentid, channel) {
    let selectedSegmentById = segmentCount[segmentType].filter((segment) => segment.id === segmentid);
    let count = 0;
    if(selectedSegmentById.length && segmentType === getTargetAudienceTypes().CUSTOM_SEGMENT){
        count = channel === 'email' ? selectedSegmentById[0]?.aggregates?.count_email : selectedSegmentById[0]?.aggregates?.count_phone;
    } else {
        count = channel === 'email' ? selectedSegmentById[0]?.count_email : selectedSegmentById[0]?.count_phone;
    }
    return count;
}

export let updateSegment = (selectedSegment) => {
    getSegmentCount("custom", selectedSegment.id, this.selectedChannel).then(res => {
        //updateReachCount(method, res)
        selectedSegment["count"] = res;
        // recalculateReach()
    })
};
export const getSegmentCounts = (id) => {
    let url = ENDPOINTS.predefinedSegmentCount + "/" + id +"/customers/segments/custom/customer_count"
    return axiosInstance.post(url,{});
}
export const getSegmentCountsAllBanks = (tokens) => {
    let url = "/analytics/rest/v1/run_query_multi/customer/segments/custom/customer_count"
    return axiosInstance.post(url,{org_ids : tokens});
}
export const fetchCsvHeaders = (channel, csvUrl, token) => {
    let url = ENDPOINTS.csvHeaders + channel;
    return axiosInstance.post(url, [], { params: { url: btoa(csvUrl), datatype: 'existingCsv', orgToken: token.org_token } });
}

export const validateCsv = (mappedHeaders, orgToken, csvUrl, channel) => {
    let url = ENDPOINTS.subscribersCount + channel;
    let headers = {
        key: channel === 'email' ? 'email' : 'phone', mapped: mappedHeaders.index
    }
    return axiosInstance.post(url, { mapped_headers: [headers] }, { params: { url: btoa(csvUrl), datatype: 'existingCsv', orgToken: orgToken.org_token } })
}

export const saveValidationData = (file, channel, orgToken) => {
    let url = ENDPOINTS.saveValidationData + channel;
    let postData = { fileId: file.id, validationData: file.extraData.validationData, mapped_headers: file.extraData.mapped_headers, headers: file.extraData?.headers, }
    return axiosInstance.post(url, postData, { params: { orgToken: orgToken.org_token } })
}

export const fetchTemplateTG = (channel, orgToken) => {
    
    let url = ENDPOINTS.templates[channel]+ (Array.isArray(orgToken) &&  ( "?org_tokens="+(JSON.stringify(orgToken).replace("[","").replace("]", "").replaceAll('"',"")))) 
    return axiosInstance.get(url)
}

export const fetchTemplate = (channel, orgToken,uuid) => {
    let url = ENDPOINTS.templates[channel]
    if(channel == "sms"){
         url = ENDPOINTS.templates[channel]+"?selectedGatewayUuid="+uuid;
    }
    return axiosInstance.get(url, { params: { orgToken: orgToken } })
}


export const publishCampaign = (orgToken, id, scheduleData, action) => {
    let publishData = preparePublishData(id, scheduleData, action)
    let url = ENDPOINTS.publishCampaign;
    return axiosInstance.post(url, publishData, { params: { orgToken: orgToken } })
}

export const publishCampaignAllBanks = (draftResponse,data) => {
    let publishData = preparePublishDataAllBanks( draftResponse,data)
    let url = ENDPOINTS.publishCampaignAll;
    return axiosInstance.post(url, publishData)
}

export const publishABCampaign = (data, scheduleData, action, orgId) => {
    
    console.log("scheduleData",scheduleData)
    
    let publishData = data.map((datum, i) => {
        
        if(!_.isEmpty(scheduleData[`targetRatios`] )){  
             (scheduleData.scheduleAt = scheduleData.targetRatios[i].scheduleAt);
             scheduleData.action = "SCHEDULED";
                
        }
        
        return preparePublishData(datum.id, scheduleData,action, orgId)
    }
    );
    let url = ENDPOINTS.publishABCampaign;
    return axiosInstance.post(url, publishData)
}

export const fetchEventNameJson = () => {
    let url = ENDPOINTS.eventNameJson;
    return axiosInstance.get(url);
}

export const saveFlow = (data, uuid, editRecipe, actiontype) => {
    let url = '/rest/v1/eventserver/rule';
    url = !!uuid && !actiontype ? `${url}/${uuid}` : url;
    let method = _.partial(axiosInstance.post);
    if (!!editRecipe && !actiontype) {
        url = `${url}?editRecipe=1`;
    } else {
        method = !!uuid && !actiontype ? _.partial(axiosInstance.put) : _.partial(axiosInstance.post);
    }
    return method(url, data);
}

export const getFlowsList = (currentPage, keyword, status) => {
    let url = ENDPOINTS.getFlows + `?page=${currentPage - 1}&perpage=10&keyword=${keyword ?? ''}&status=${status ?? ''}`;
    return axiosInstance.get(url)

}

export const toggleFlow = (uuid) => {
    let url = ENDPOINTS.toggleRule + uuid;
    return axiosInstance.get(url)
}

export const deleteRule = (uuid) => {
    let url = ENDPOINTS.deleteRule + uuid;
    return axiosInstance.delete(url)
}

export const fetchRule = (uuid) => {
    let url = ENDPOINTS.fetchRule + uuid;
    return axiosInstance.get(url);
}

export const getShortenedUrl = async (value) => {
    const orgToken = store.getState().auth?.selectedOrg?.token;
    const url = `/broadcast/shortenurl?longUrl=${btoa(value)}&orgToken=${orgToken}`;
    return await axiosInstance.get(url)
}

export const editRecipeDetails = (selectedRecipe) => {
    console.log('selected recipe', selectedRecipe)
    let url = ENDPOINTS.editRecipeDetails + selectedRecipe.readableName;
    return axiosInstance.post(url, {
        discountData: selectedRecipe?.discountData,
        extraData: selectedRecipe?.extraData
    });
}

export const getOrgList = (token) => {
    return axiosInstance.get(`${process.env.REACT_APP_API_ENDPOINT}/rest/v1/org/list`, { headers: { token: token } })
}

export const getStriptCredentials = () => {
    const url = ENDPOINTS.stripoCredentials;
    return axiosInstance.get(url)
}

export const postEmailTemplate = (formData, uuid,orgToken) => {
    let url = ENDPOINTS.saveEmailTemplate
    if(window.location.href.includes("all-banks")){
        url = ENDPOINTS.saveEmailTemplate +"?orgToken="+orgToken;
    }
    !!uuid && (url = ENDPOINTS.saveEmailTemplate + '/' + uuid);
    let method = _.isEmpty(uuid) ? _.partial(axiosInstance.post) : _.partial(axiosInstance.put);
    return method(url, formData, {
        headers: {
            'Content-Type': undefined
        }
    })

}
export const postApprovalAllBanks  = (status,uuid)=>{
    let url =    ENDPOINTS.postApprovalAllBanks
    let data = {
        approval_status: status,
        uuid : uuid
    }
    axiosInstance.post(url,data)
}
export const getEmailTemplateList = (currentPage, keyword) => {
    let url = ENDPOINTS.getEmailTemplateList + `?perpage=20&keyword=${keyword}&page=${currentPage - 1}`;
    return axiosInstance.get(url)
}

export const fetchEmailSavedTemplate = (uuid) => {
    let url = ENDPOINTS.fetchEmailSavedTemplate + uuid;
    return axiosInstance.get(url);
}

export const deleteEmailTemplate = (uuid) => {
    let url = ENDPOINTS.deleteEmailTemplate + uuid;
    return axiosInstance.delete(url);
}

export const postSmsTemplate = (formData) => {
    let url = ENDPOINTS.saveSmsTemplate;
    return axiosInstance.post(url, formData, {
        headers: {
            'Content-Type': undefined
        }
    })
}

export const getSmsTemplateList = (page, perPage) => {
    let url = ENDPOINTS.getSmsTemplateList + '?' + 'page=' + (page - 1) + '&perpage=' + perPage;
    return axiosInstance.get(url)
}

export const getPushTemplateList = (page, perPage) => {
    let url = ENDPOINTS.getPushTemplateList + '?' + 'page=' + (page - 1) + '&perpage=' + perPage;
    return axiosInstance.get(url)
}


export const deleteSmsTemplate = (uuid) => {
    let url = ENDPOINTS.deleteSmsTemplate + uuid
    return axiosInstance.delete(url)

}
export const deletePushTemplate = (uuid) => {
    let url = ENDPOINTS.getPushTemplateList + "/"+ uuid
    return axiosInstance.delete(url)

}
export const updateSmsTemplate = (values, uuid) => {
    let url = ENDPOINTS.saveSmsTemplate + "/" + uuid
    return axiosInstance.put(url, values)
}

export const postWhatsappTemplate = (formData) => {
    let url = ENDPOINTS.saveWhatsapptemplate;
    return axiosInstance.post(url, formData, {
        headers: {
            'Content-Type': undefined
        }
    })
}

export const applyWhatsappTemplate = (formData) => {
    let url = ENDPOINTS.applyWhatsapptemplate;
    return axiosInstance.post(url, formData, {
        headers: {
            'Content-Type': undefined
        }
    })
}
export const getWhatsappTemplateList = (page, perPage) => {
    let url = ENDPOINTS.getWhatsappTemplateList + '?' + 'pageNum=' + (page - 1) + '&pageLimit=' + perPage;
    return axiosInstance.get(url)
}

export const updateWhatsappTemplate = (formData, uuid) => {
    let url = ENDPOINTS.saveWhatsapptemplate + "/" + uuid;
    return axiosInstance.put(url, formData, {
        headers: {
            'Content-Type': undefined
        }
    })
}
export const deleteWhatsapptemplate = (uuid) => {
    let url = ENDPOINTS.saveWhatsapptemplate + "/" + uuid;
    return axiosInstance.delete(url)
}

export const getSegmentationProps = (token) => {
    let url = '/rest/v1/segmentation/getprops';
    if(!!token){
        url ='/rest/v1/segmentation/getprops?orgToken=' + token 
    }
    return axiosInstance.get(url)
}

export const getImageGallery = (page, perPage) => {
    let url = ENDPOINTS.getImageGallery + "?" + "page=" + (page - 1) + "&perpage=" + perPage;
    return axiosInstance.get(url)
}
export const uploadImage = (image) => {
    let url = ENDPOINTS.uploadImage;
    const formData = new FormData();
    formData.append('image', image);
    return axiosInstance.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
}


export const saveOnsiteTemplate = (data, uuid) => {
    let url = ENDPOINTS.saveNudgeTemplate + uuid;
    return axiosInstance.post(url, data);
}

export const getOnsiteNudgeTemplateList = () => {
    let url = ENDPOINTS.getNudgeTemplateList;
    return axiosInstance.get(url);
}

export const fetchNudgeTemplateData = (uuid) => {
    let url = ENDPOINTS.fetchNudgeTemplate + uuid;
    return axiosInstance.get(url);
}

export const deleteNudgeTemplate = (uuid) => {
    let url = ENDPOINTS.deleteNudgeTemplate + uuid;
    return axiosInstance.delete(url);

}
export const getSmsGatways = (orgToken) => {
    let url = ENDPOINTS.smsGatwayList 
    if(!!orgToken){
        url = ENDPOINTS.smsGatwayList  + "?orgToken=" + orgToken
    }
    return axiosInstance.get(url)
}
export const refreshSegment = (id,segment_id) => {
    let url = `/analytics/rest/v1/run_query/org/${id}/segments/custom/${segment_id}/refresh`;
    return axiosInstance.post(url,{})
}

export const saveSegment = (data) => {
    let url = `/analytics/rest/v1/run_query/org/${data.org_id}/customer/segments/create?orgToken=${data.org_id}`;
    return axiosInstance.post(url, data);
}

export const fetchSegmentList = (orgId, pageNum) => {
    let url = `/analytics/rest/v1/run_query/org/${orgId}/customers/segments/custom/data/page/${pageNum - 1}/page_size/10`;
    return axiosInstance.post(url, {})
}
export const deleteCustomSegment = (segmentId, orgId) => {
    let url = `/analytics/rest/v1/run_query/org/${segmentId}/segments/custom/delete/${orgId}`;
    return axiosInstance.post(url, {})
}

export const toggleCustomSegmentStatus = (orgId, segmentId, status) => {
    let url = `/analytics/rest/v1/run_query/org/${orgId}/customers/segments/custom/${segmentId}/status?orgToken=${orgId}`;
    let postData = {
        "custom_segment_data": {
            "org_id": orgId,
            "segment_id": segmentId,
            "pre_cache_status": (status === 'ENABLED') ? 'DISABLED' : "ENABLED"
        }
    };
    return axiosInstance.post(url, postData);
}

export const sendTestWhatsapp = (data, orgToken) => {

    let url = ENDPOINTS.sendTestWhatsapp
    if(!!orgToken){
        url = ENDPOINTS.sendTestWhatsapp + "?orgToken=" + orgToken  
    }
    return axiosInstance.post(url, data)
}

export const sendTestSms = (data, orgToken) => {

    let url = ENDPOINTS.sendTestSms
    if(!!orgToken){
        url = ENDPOINTS.sendTestSms + "?orgToken=" + orgToken  
    }
    return axiosInstance.post(url, data)
}
export const sendTestEmailMulti = (data, orgToken) => {
    let url = ENDPOINTS.sendTestEmailMulti
    
    return axiosInstance.post(url, data)
}
export const sendTestEmail = (data, orgToken) => {
    let url = ENDPOINTS.sendTestEmail
    if(!!orgToken){
        url = ENDPOINTS.sendTestEmail + "?orgToken=" + orgToken
    }
    return axiosInstance.post(url, data)
}
export const getEmailConf = (token) => {
    let url = ENDPOINTS.emailConfigurations + "?orgToken=" + token
    return axiosInstance.get(url)
}
export const getCustomSegments = (orgId) => {
    let url = `/analytics/rest/v1/run_query/org/${orgId}/customer/segments/custom/list/name`;
    return axiosInstance.post(url, {})
}
export const sendSegmentEvents = (id) => {
    let url = `/analytics/rest/v1/run_query/org/${id}/customer/segments/custom/list/name`
    return axiosInstance.post(url, {})

}
export const getSegmentProps = () => {
    let url = `/rest/v1/segmentation/getprops`
    return axiosInstance.get(url)
}
export const sendWhatsAppChannel=(data)=>{
    let url=ENDPOINTS.whatsAppChannelRegister
    return axiosInstance.post(url,data)

}
export const getChanneldata=(orgToken)=>{
    let url=`/whatsapp/credentials/${orgToken}`
    return axiosInstance.get(url,orgToken)
}
export const sendSmsChannelData=(data,gateway)=>{
    let url=`sms/integrations/${gateway}`;
    return axiosInstance.post(url,data);
}
export const getSmsChannelData=()=>{
    let url=`/sms/integrations`;
    return axiosInstance.get(url);
}
export const whatsappChannelAccess=()=>{
    let url=`/whatsapp/hasaccess`;
    return axiosInstance.get(url);
}

export const refreshToken=()=>{
    let url='/video/auth/login/refresh'
    return axiosInstance.post(url);
}
export const getCountsFromQuery = (orgId, query)=>{
    
    if(typeof query === 'object' && !!query["0"]){
        query = query["0"]
    }
    let url =ENDPOINTS.getCountsFromQuery+orgId+"/custom/get_counts?orgToken="+orgId 
    let data={
        'encoded_query' : btoa(query) 
    }
    return axiosInstance.post(url,data);
}
export const getCountsFromQueryMulti = (org_ids, query)=>{
    
    let url =ENDPOINTS.getCountsFromQueryMulti + "/custom/get_counts"
    let data={
        org_ids : org_ids,
        'encoded_query' : btoa(query) 
    }
    return axiosInstance.post(url,data);
}
export const getOrders=(orgId,currentPage)=>{
    const url= `analytics/rest/v1/run_query/org/${orgId}/segment/orders/page/${currentPage}/page_size/50`
    return axiosInstance.post(url,{})
   
}
export const exportCSV=(orgId,data)=>{
    const url=`analytics/rest/v1/chunked/run_query/org/${orgId}/orders/downloadcsv`
    return axiosInstance.post(url,data)
}
export const getEnabledMenuItems=()=>{
    const url=`enabledmenuitems`
    return axiosInstance.get(url)
}
export const getBroadcastHeaders=()=>{
    const url=`broadcastheaders`
    return axiosInstance.get(url)
}
export function refreshJWT(jwt) {
    const url=`${process.env.REACT_APP_API_ENDPOINT}/video/auth/login/refresh`;
    return axiosInstance.post(url,jwt)
}


// export const downloadcsv


export const loginWithFacebook = () => {
    const url = ENDPOINTS.getFacebookLoginUrl;
    return axiosInstance.get(url)
}

export const getLiveActivitiesFeed = ()=>{
    let url = "rest/v1/liveactivities/activityfeed"
    return axiosInstance.get(url)
}

export const searhcSuperAdmin = (payload) => {
    const url = "su/searchorg";
    return axiosInstance.post(url, payload);
};
export const getUserList=(token)=>{
    let url= `/su/allusers/${token}`
    return axiosInstance.get(url)
}
export const getAllUsers=(token)=>{
    let url=`/su/orgfeatures/allusers/${token}`
    return axiosInstance.get(url)
}
export const getOrgFeatures=(token)=>{
    let url=`/su/orgfeatures/${token}`
    return axiosInstance.get(url)
}
export const getUserAll=(token)=>{
    let url=`/su/rest/v1/users/${token}`
    return axiosInstance.get(url)
}
export const updateUserPermissions=(token,value)=>{
    let url=`/su/rest/v1/users/${token}`
    return axiosInstance.post(url,value)
}
export const setSFTPCredintials=(data)=>{
    let url=`/su/sftp/credentials`
    return axiosInstance.post(url,data)
}
export const getSFTPCredintials=()=>{
    let url=`/su/sftp/credentials`
    return axiosInstance.get(url)
}

export const syncAnalyticsDB=(data,token)=>{
    let url=`/su/rest/v1/sync-analytics-db/${token}`
    return axiosInstance.post(url,data)
}
export const addAgencyUsers=(data,token)=>{
    let url=`/su/rest/v1/agency/users/${token}`
    return axiosInstance.post(url,data)
}
export const deleteAgencyUser = (email, token) => {
    const url = `/su/rest/v1/agency/users/${token}`;
    return axiosInstance.delete(url, { data: { email } });
  };
  
  export const powerBiSettings = (data, token) => {
    let url = `/su/setting/powerbi_url/${token}`;
    return axiosInstance.post(url, data);
}
export const getSyncAnalyticsDB = (token) => {
    const url = `/su/rest/v1/sync-analytics-db/${token}`;
    return axiosInstance.get(url);
};

export const getPowerBiSettings = (token) => {
    let url = `/su/setting/powerbi_url/${token}`;
    return axiosInstance.get(url);
}

export const getSuperAdminOrgs=(page = 0)=>{
    let url=`/su/orgs?page=${page}`
    return axiosInstance.get(url)
}
export const fetchPowerBiUrl=()=>{
    let url=`/dashboard/v6/powerbi-url`
    return axiosInstance.get(url)      
}

export const saveAppPushTemplate = (data) => {
    let url = "/rest/v1/push/android/template"
    return axiosInstance.post(url,data)
}

export const getMobilePushTemplateList = ()=>{
    let url = "/rest/v1/push/android/template-list?endDate=2024-09-24&page=-1&perPage=10&pushType=pushNotification&startDate=2024-08-24&targetPlatform=&templateName="
    return axiosInstance.get(url)
}

export const forgotPSWEmail = (email) => {
    const url = `/auth/rest/v1/forgot`;
    return axiosInstance.get(url, { params: { email } });
};

export const verifySenderMail=(data)=>{
    const url=`account/email-channels/verify-sender-mail`
    return axiosInstance.post(url,data)
}
export const saveEmailChannels=(data)=>{
    const url=`/account/rest/v1/email-channels`
    return axiosInstance.post(url,data)
}

export const fetchEmailChannels=(orgToken)=>{
    let url=`/account/rest/v1/email-channels`
    if(!!orgToken){
        url = '/account/rest/v1/email-channels?orgToken=' + orgToken;
    }
    return axiosInstance.get(url)
}
export const updateSMPT=(uuid,smtptype)=>{
    let url=`/account/rest/v1/email-channels/setdefault/${smtptype}/${uuid}`
    return axiosInstance.post(url)
}
export const deleteEmailChannels=(uuid)=>{
    let url=`/account/rest/v1/email-channels/${uuid}`
    return axiosInstance.delete(url)
}
export const customerDB = (data, token) => {
    let url = `/su/rest/v1/sync-customer-db/${token}`;
    return axiosInstance.post(url, data);
}
export const getCustomerDB=(token)=>{
    let url=`/su/rest/v1/sync-customer-db/${token}`;
    return axiosInstance.get(url);
    
}
export const transctionDataBycustomers=()=>{
    let url=`/dashboard/v6/transaction-by-customers`
    return axiosInstance.get(url)
}

export const getSegmentForSuppersionList=(orgToken,data)=>{
    let url=`/analytics/rest/v1/run_query/org/${orgToken}/customer/segments/custom/list/name`
    return axiosInstance.post(url,data);
}
export const addExclusionList=(segmentId)=>{
    let url=`/suppression/exclusionList/${segmentId}`
    return axiosInstance.post(url);
}
export const deleteCampaign = (uuid)=>{
    let url = `/broadcast/campaign/delete/${uuid}`
    return axiosInstance.delete(url)
}

export const getWhatsappTemplateDetails = (uuid)=>{
    let url = ENDPOINTS.getWhatsappTemplateDetails+"/"+ uuid;
    return axiosInstance.get(url)
}
export const saveDraftAllBanks = (data)=>{
    let url = ENDPOINTS.saveDraftAllBanks;
    return axiosInstance.post(url, data)
}

export const getAIBlockFormElements = (blockType, uuid)=>{
    let url = ENDPOINTS.getAIBlockFormElements + "?blocktype=" + blockType + "&uuid=" + uuid;
    return axiosInstance.get(url)
}
export const getBlockMarkup = (blockType, uuid, data)=>{
    let url = ENDPOINTS.getBlockMarkup + "?blocktype=" + blockType + "&uuid=" + uuid;
    return axiosInstance.post(url, data)
}

export const saveOrUpdateBlock = (blockType, uuid, data)=>{
    let url = ENDPOINTS.saveOrUpdateBlock + "?blocktype=" + blockType + "&uuid=" + uuid;
    return axiosInstance.post(url, data)
}

export const getAllBlocks = ()=>{
    let url = ENDPOINTS.getAllBlocks;
    return axiosInstance.get(url)
}
export const deleteBlock = (uuid)=>{
    let url = ENDPOINTS.getAllBlocks;
    return axiosInstance.delete(url + "/" + uuid, {data: {uuid}})
}

export const getSegmentationJson = () => {
  return {
    criteria_type: 'intermediate',
    criteria_operation: 'and',
    criteria: [],
  };
};

    export const getTargetList = (orgId, target, pageNum, perPage, order, orderBy, fromPagination, segmentationJson) => {
  let url = `/analytics/rest/v1/run_query/org/${orgId}/segment/${target}/page/${pageNum - 1}/page_size/${perPage}`;
  const data = {
    target: target,
    org_id: orgId,
    criteria: segmentationJson,
  };
  if (target === 'orders' && fromPagination) {
    data['flag'] = 'paginate';
  }
  return axiosInstance.post(url, data);
};


export const getAvailableHeaders = async () => {
  const url = `/analytics/getcontactheaderlist`;
  try {
    const response = await axiosInstance.get(url);
    if (response.data?.headers) {
      return response.data.headers;
    }
    return [];
  } catch (error) {
    console.error('Error fetching available headers:', error);
    return [];
  }
};

export const resetPassword = async (data) => {
    const url = "/auth/reset/" + data.uuid
    const formData = new FormData()
    formData.append('newpass', data.password)
    formData.append('retypepass', data.password)
    formData.append('authid', data.uuid)
    formData.append('api', true)
    return axiosInstance.post(url,formData,{headers:{"Content-Type":" multipart/form-data;"}});
}
export const resetPasswordForSU = async (data) => {
    const url = "/su/resetpassword"
    return axiosInstance.post(url, data);
}

export const getAllUsersForSU = async (pageNum, perPage, searchTerm) => {
    const url = `/su/allusers?page=${pageNum}&perpage=${perPage}&search=${searchTerm}`;
    return axiosInstance.get(url)
};

export const updateUserForSU = async (data) => {
    const url = `/su/allusers`;
    return axiosInstance.post(url, data)
};


export const getCustomerDetails = async (orgId, customerId) => {
  const url = `/analytics/rest/v1/run_query/org/${orgId}/customers/${customerId}?orgToken=${orgId}`;
  try {
    const response = await axiosInstance.post(url, {});
    return response.data;
  } catch (error) {
    console.error('Error fetching customer details:', error);
    throw error;
  }
};