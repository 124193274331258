import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { getTokenFromLoacalStorage, tokenTimeDifference } from "../_helpers/helpers";
import { logout } from "./AuthService";
import { refreshJWT } from "./api";
import { OvalSpinner } from "../components/Loaders";
import store from "../store/store";
import _ from "lodash";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";
import moment from "moment";
import { Button } from "@chakra-ui/react";
import { LOGIN_CONFIRMED, LOGOUT, SET_ORG } from "../store/Reducers/AuthReducer";
import { extractUserDataFromJWT } from "../_helpers/helpers";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
const { dispatch } = store;
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT
});
function addHeadersToConfig(config) {
    if (!_.isEmpty(config)) {
        config.params = config.params || {};
        config.headers['jwtAuth'] = true;
        return config;
    }
    return config;
}
function handleLogoutClick(){
    Swal.close();
    logout(dispatch)
}
axiosInstance.interceptors.request.use(
    async (config) => {
        config = addHeadersToConfig(config);
        
        let { user_data, jwt, exp, token } = getTokenFromLoacalStorage();
        console.log(exp, "exp logedin");
        
        if (config.url.includes('orgToken') || !!config.params.orgToken ) {
            console.log("has Token");
        } else {
            config.params = { ...config.params, orgToken: token };
        }

        if (!_.isEmpty(jwt)) {
            
            const tokenTimeDiff = tokenTimeDifference(exp);
            // console.log(tokenTimeDiff, "tokenTimeDiff");
            if (tokenTimeDiff ==-5) {
                console.log("Token expired");
                await showExpirationPopup(jwt);
                // Refresh token after popup closes
                const updatedToken = getTokenFromLoacalStorage().jwt;
                config.headers["token"] = updatedToken;
                return config;
            } else {
                config.headers["token"] = jwt;
                return config;
            }
        } else {
            if (config.url.includes('whitelabel') || config.url.includes('auth')) {
                return config;
            } else {
                logout(dispatch);
                return;
            }
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);


// axiosInstance.interceptors.request.use(
//     async (config) => {
        
//         const isForgotPassword = config.url.includes('/auth/rest/v1/forgot');
//         console.log(isForgotPassword,"isForgotPasswordisForgotPassword",config,"config")

//         if (isForgotPassword) {
            
//             return config;
//         }

//         config = addHeadersToConfig(config);

//         let { user_data, jwt, exp, token } = getTokenFromLoacalStorage();
//         console.log(exp, "exp logged in");
        
//         if (config.url.includes('orgToken') || !!config.params.orgToken) {
//             console.log("has Token");
//         } else {
//             config.params = { ...config.params, orgToken: token };
//         }

//         if (!_.isEmpty(jwt)) {
//             const tokenTimeDiff = tokenTimeDifference(exp);
//             console.log(tokenTimeDiff, "tokenTimeDiff");
//             if (tokenTimeDiff === -5) {
//                 console.log("Token expired");
//                 await showExpirationPopup(jwt);
//                 const updatedToken = getTokenFromLoacalStorage().jwt;
//                 let decodedToken = extractUserDataFromJWT(updatedToken);
//                 console.log(updatedToken,"updatedTokenupdatedToken")
//                 console.log(decodedToken,"decodedToke")
//                 config.headers["token"] = updatedToken;
//                 return config;
//             } else {
//                 config.headers["token"] = jwt;
//                 return config;
//             }
//         } else {
//             if (config.url.includes('whitelabel')) {
//                 return config; // Allow whitelabel requests
//             } else {
//                 logout(dispatch);
//                 return Promise.reject(new Error("User not authenticated"));
//             }
//         }
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

    axiosInstance.interceptors.response.use(
        (response) => {
          
          return response;
        },
        (error) => {
          if (error.response) {
            const status = error.response.status;
            if (status === 401) {
              console.log("401 Unauthorized: Logging out...");
              logout(dispatch);
              window.location.href = "/auth/login";
            } else if (status === 404) {
              console.log("404 Not Found: Showing toast...");
        
            }
          } else {
            console.error("Unexpected error:", error.message);
          }
          return Promise.reject(error);
        }
      );
    

let isPopupOpen = false;

async function showExpirationPopup(jwt) {
    if (isPopupOpen) return; 
    isPopupOpen = true;

    
    const spinnerContainer = document.createElement('div');
    document.body.appendChild(spinnerContainer);


    const spinnerRoot = ReactDOM.createRoot(spinnerContainer);
    spinnerRoot.render(<OvalSpinner />);

    
    const { value: shouldLogin } = await Swal.fire({
        title: 'Session Expiring Soon',
        text: 'Your session is about to expire. Do you want to stay logged in?',
        showCancelButton: true,
        confirmButtonText: 'Login Again',
        cancelButtonText: 'Log Out',
        reverseButtons: true,
        customClass: {
            cancelButton: 'swal2-cancel'
        },
        didOpen: () => {
            
            const popup = Swal.getPopup();
            const spinnerPlaceholder = document.createElement('div');
            spinnerPlaceholder.id = 'swal-spinner-container';
            popup.appendChild(spinnerPlaceholder);

            spinnerRoot.render(<OvalSpinner />);
            const cancelButton = Swal.getCancelButton();

            cancelButton.addEventListener('click', () => {
                handleLogoutClick();
            });
           
        },
        preConfirm: async () => {
            
            document.getElementById('swal-spinner-container').style.display = 'block';
            const response = await refreshJWT(jwt);
            // console.log(response,"response for new jwt")
            return response;
        },
        
        willClose: () => {
           
            spinnerRoot.unmount();
            document.body.removeChild(spinnerContainer);
            isPopupOpen = false; 
        }
    });

    if (shouldLogin) {
        try {
            const response = shouldLogin;
            
            // console.log(response,"response for new tokennnnnn")
            const newToken = JSON.parse(localStorage.getItem("selectedOrg"))?.token || response?.data?.data?.userData?.selectedOrg?.token;
            const newTokenExp = jwtDecode(response.data?.data?.jwt).exp;
            // console.log(newToken,"newToken")
            // console.log(newTokenExp,"newTokenExp")

            if (newToken) {
                // dispatch(LOGIN_CONFIRMED({token: jwt, user_data: user_data, exp: exp}));
                // dispatch(SET_ORG({data: selectedOrg}));
                localStorage.setItem('token', JSON.stringify({
                    exp: new Date(newTokenExp*1000).toISOString(),
                    jwt: response.data?.data?.jwt,
                    user_data: response.data.data.userData,
                    isSuperAdmin:response.data.data.isSuperAdmin
                }));
                dispatch(LOGIN_CONFIRMED({token: response.data?.data?.jwt, user_data: response?.data?.data.userData,isSuperAdmin:response?.data?.data?.isSuperAdmin, exp: new Date(newTokenExp*1000).toISOString()}));
                dispatch(SET_ORG({data: JSON.parse(localStorage.getItem("selectedOrg")) }));
                // localStorage.setItem('selectedOrg', JSON.stringify(response.data.data.userData.selectedOrg));
                console.log("Token refreshed and stored");
            }
        } catch (error) {
            console.error("Failed to refresh token", error);
        }
        
    }

    
    document.getElementById('swal-spinner-container').style.display = 'none';
}

export default axiosInstance;
