import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  Select,
  Flex,
  FormErrorMessage,
  Text,
  RadioGroup,
  Radio,
  Checkbox,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { emailProviders } from "../../_helpers/data_helper";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import EmailProviderMapping from "./EmailProviderMapping";
import { useToast } from "@chakra-ui/react";
import { fetchEmailChannels, saveEmailChannels } from "../../Services/api";

const Transactional = ({ onSave }) => {
  const initialData = {
    AmazonSES: {
      credentials: {
        apiKey: "",
        senderId: "",
        backend: "AmazonSes",
        name: "",
        isNew: true,
      },
    },
    DiskWriter: {
      credentials: { localPath: "", backend: "DiskWriter", name: "" },
    },
    GenericSMTP: {
      credentials: {
        host: "",
        port: "",
        backend: "SMTP",
        username: "",
        name: "",
        password: "",
        from: "",
        replyTo: "",
        security: "",
      },
    },
    Mandrill: {
      credentials: {
        from: "",
        key: "",
        backend: "Mandrill",
        name: "",
        replyTo: "",
      },
    },
    Netcore: {
      credentials: {
        apiKey: "",
        from: "",
        backend: "Netcore",
        name: "",
        replyTo: "",
      },
    },
    SendGrid: {
      credentials: {
        apiKey: "",
        from: "",
        backend: "SendGrid",
        name: "",
        replyTo: "",
      },
    },
    SparkPost: {
      credentials: {
        apiKey: "",
        from: "",
        backend: "SparkPost",
        name: "",
        replyTo: "",
      },
    },
  };

  const [selectedGateway, setSelectedGateway] = useState(emailProviders[0].key);
  const [emailType, setEmailType] = useState({
    transactional: true,
    promotional: true,
  });
  const [initialValues, setInitialValues] = useState({
    provider: selectedGateway,
    credentials: initialData[selectedGateway].credentials,
  });
  const [gatewayData, setGatewayData] = useState({});
  const toast = useToast();
  const formikRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const EmailComponent = EmailProviderMapping[selectedGateway];

  const fetchTransactionalData = async () => {
    try {
      const response = await fetchEmailChannels();
      console.log(response, "response of list");
    } catch (error) {
      console.log("Error while fetching list");
    }
  };

  useEffect(() => {
    fetchTransactionalData();
    setInitialValues({
      provider: selectedGateway,
      credentials: initialData[selectedGateway].credentials,
    });
  }, [selectedGateway]);

  const handleSubmit = async (values, actions) => {
    const { provider, credentials } = values;

    const registerEmailChannel = async () => {
      try {
        setLoading(true);
        
        const dataToSend = {
          name: credentials.name,
          credentials: {
            ...credentials,
            backend: credentials.backend,
            port: credentials.port || 80,
          },
          extraData: {
            test: false,
          },
          setDefaultTransactional: emailType.transactional,
          setDefaultPromotional: emailType.promotional,
        };

        const response = await saveEmailChannels(dataToSend);
        toast({
          title: "Success",
          description: "Email Credentials Saved Successfully.",
          status: "success",
        });

        if (onSave) onSave();
        return response;
      } catch (error) {
        console.error("Error while saving Email Channel", error);
        toast({
          title: "Oops!",
          description: "Error in saving credentials.",
          status: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    await registerEmailChannel();
    actions.setSubmitting(false);
  };

  const handleGatewayChange = (event) => {
    const selectedKey = event.target.value;
    setSelectedGateway(selectedKey);
    setInitialValues({
      provider: selectedKey,
      credentials: initialData[selectedKey].credentials,
    });
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Box backgroundColor="#fff" p={4}>
          <Form>
            <Flex direction="column" align="center" justify="center">
              <Box
                width="100%"
                maxW="500px"
                p="2rem"
                borderRadius="md"
                boxShadow="sm"
              >
                <Text fontSize="1.2rem" mb="1rem" fontWeight="bold">
                  Email Gateway
                </Text>

                <FormControl
                  mb={4}
                  isInvalid={
                    formikProps.errors.provider && formikProps.touched.provider
                  }
                >
                  <Field
                    as={Select}
                    id="provider"
                    name="provider"
                    onChange={handleGatewayChange}
                  >
                    {emailProviders.map((option) => (
                      <option key={option.key} value={option.key}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <FormErrorMessage>
                    {formikProps.errors.provider}
                  </FormErrorMessage>
                </FormControl>

                <FormControl mb={4}>
                  <Text fontSize="1.2rem" mb="1rem" fontWeight="bold">
                    Email Type
                  </Text>
                  <Flex direction="row" justify="space-between">
                    <Checkbox
                      isChecked={emailType.transactional}
                      onChange={() =>
                        setEmailType((prev) => ({
                          ...prev,
                          transactional: !prev.transactional,
                        }))
                      }
                    >
                      Default Transactional
                    </Checkbox>
                    <Checkbox
                      isChecked={emailType.promotional}
                      onChange={() =>
                        setEmailType((prev) => ({
                          ...prev,
                          promotional: !prev.promotional,
                        }))
                      }
                    >
                      Default Promotional
                    </Checkbox>
                  </Flex>
                </FormControl>

                {EmailComponent && (
                  <EmailComponent
                    setFieldValue={formikProps.setFieldValue}
                    index={0}
                    setGatewayData={setGatewayData}
                  />
                )}

                <Flex
                  direction="row"
                  align="center"
                  justify="space-between"
                  gap={4}
                  mt={4}
                >
                  <PrimaryButton
                    label="Save"
                    type="submit"
                    isLoading={formikProps.isSubmitting || loading}
                  />
                </Flex>
              </Box>
            </Flex>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default Transactional;
