import React, { useEffect, useState } from 'react';
import { getAllBlocks } from '../../Services/api';
import './BlockOverview.css';
import noBlockImg from "../../images/no-blocks.png";
import { FaTrash } from 'react-icons/fa';

const BlockOverview = () => {
  const [blocks, setBlocks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBlock, setSelectedBlock] = useState(null); // For modal visibility

  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        const response = await getAllBlocks();
        setBlocks(response.data || []);
      } catch (error) {
        console.error('Error fetching blocks:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlocks();
  }, []);

  const handleDelete = (blockId) => {
    if (window.confirm('Are you sure you want to delete this block?')) {
      console.log(`Delete block with id: ${blockId}`);
      // Implement delete logic here
    }
  };

  const openPreviewModal = (block) => {
    console.log('Opening modal for block:', block); // Debugging log
    setSelectedBlock(block); // Set the selected block
  };

  const closePreviewModal = () => {
    console.log('Closing modal'); // Debugging log
    setSelectedBlock(null); // Close the modal
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="custom-loader">
          <div className="loader-circle"></div>
          <p>Loading blocks, please wait...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="block-overview-container">
      {blocks.length === 0 ? (
        <div className="no-blocks-container">
          <h1>Hey!</h1>
          <h3>It seems like you don’t have any blocks yet.</h3>
          <button
            className="create-button"
            onClick={() => (window.location.href = '/ai-block/create')}
          >
            Create New Block
          </button>
          <p>Custom blocks help personalize your workflow.</p>
          <img src={noBlockImg} alt="No Blocks" className="no-blocks-image" />
        </div>
      ) : (
        <div>
          <div className="header">
            <h1>Block Overview</h1>
            <button
              className="primary-button"
              onClick={() => (window.location.href = '/ai-block/create')}
            >
              Create New Block
            </button>
          </div>
          <div className="blocks-container">
            {blocks.map((block) => (
              <div className="block-card" key={block.uuid}>
                {/* Block Image */}
                <div className="block-image-container">
                  <img
                    src={block.previewImage}
                    alt={block.name}
                    className="block-image"
                  />
                  <div className="block-updated">
                    <span className="caption-helper">
                      Last Updated:{' '}
                      <abbr
                        className="timeago"
                        title={block.lastupdate}
                      >
                        {new Date(block.lastupdate).toLocaleString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                        })}
                      </abbr>{' '}
                    </span>
                  </div>
                </div>

                {/* Block Title */}
                <h2 className="block-title">{block.name}</h2>

                {/* Block Author */}
                <div className="block-author">
                  <img
                    src={block.authorProfilePicture}
                    alt={block.authorName}
                    className="author-image"
                  />
                  <span>{block.authorName}</span>
                </div>

                {/* Actions */}
                <div className="block-actions">
                  <a href={`/ai-block/block/${block.uuid}`} className="edit-link">
                    Edit
                  </a>
                  <button
                    className="delete-button"
                     style={{ flex: 1, textAlign: 'center', lineHeight: '32px' }}
                    onClick={() => handleDelete(block.uuid)}
                  >
                    Delete
                  </button>
                  <button
                    className="preview-button"
                    onClick={() => openPreviewModal(block)}
                  >
                    Preview Block
                  </button>
                </div>
              </div>



            ))}
          </div>
        </div>
      )}

      {/* Preview Modal */}
      {selectedBlock && (
        <div className="modal-overlay" onClick={closePreviewModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
          >
            <button className="close-modal" onClick={closePreviewModal}>
              ×
            </button>
            <h2>{selectedBlock.name}</h2>
            <img
              src={selectedBlock.previewImage}
              alt={selectedBlock.name}
              className="modal-image"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BlockOverview;
