import React, { useEffect, useState } from 'react';
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, Spinner, Text, useDisclosure, Image, useToken, Icon } from '@chakra-ui/react';
import { ProductService } from './ProductService'; // Import the service
import Swal from 'sweetalert2';
import hexToRgba from 'hex-to-rgba'
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from '@ajna/pagination';
import { Link } from 'react-router-dom';
import { BsFunnel } from 'react-icons/bs';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import FilterProductsModal from './FilterProductsModal';

const ProductsOverview = () => {
  const [productsList, setProductsList] = useState([]);
  const [segmentationJson, setSegmentationJson] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [mainColor] = useToken('colors', ['primary.main']);
  const [pagination, setPagination] = useState({ current: 1 });
  const [showLoader, setShowLoader] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [filterModalOpen,setFilterModalOpen] = useState(false);
  const orgId = 119;
  const productsLimit = 10;

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    total: totalCount,
    initialState: { currentPage: 1, pageSize: 5, isDisabled: false },
    limits: { outer: 1, inner: 1 }
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Update pagination state and fetch data when the page changes
  useEffect(() => {
    setPagination({ current: currentPage });
    getProductList();
  }, [currentPage]);

  // Initialize table data
  const initTableData = () => {
    setPagination({ current: 0 });
    setTotalItems(0);
  };

  // Fetch product list data
  const getProductList = async () => {
    setShowLoader(true);
    try {
      
      const data = ProductService.prepareDataJson(segmentationJson);
      const response = await ProductService.getTargetList(data, orgId, "product", pagination.current, 50);
      setProductsList(response?.data);
      setTotalCount(response.data[0]?._total_count); // Assuming _total_count exists in the response
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setShowLoader(false);
    }
  };

  // Handle CSV download
  const downloadCsv = async () => {
    if (downloading) return;
    setDownloading(true);

    try {
      const data = await ProductService.getChunkedTargetData(ProductService.targets.PRODUCTS, "segmentType", "segmentId");
      const csvBlob = new Blob([data], { type: 'text/csv' });
      const downloadLink = document.createElement('a');
      downloadLink.download = `customers_${Date.now()}.csv`;
      downloadLink.href = window.URL.createObjectURL(csvBlob);
      downloadLink.click();
    } catch (error) {
      console.error('Error downloading CSV:', error);
    } finally {
      setDownloading(false);
    }
  };

  // Create segment based on filtered products
  const createSegmentOnFilteredProducts = () => {
    if (totalItems > parseInt(productsLimit)) {
      Swal({
        title: "Are you sure?",
        text: `Max ${productsLimit} products can be selected, refine further or let us select top-selling products.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Create it anyway.",
      }).then((isConfirm) => {
        if (isConfirm) {
          createSegment();
        }
      });
    } else {
      createSegment();
    }
  };

  // Create a segment with selected product IDs
  const createSegment = () => {
    const productIds = productsList.slice(0, 100).map(product => product.product_id);
    const criteria = {
      criteria_type: "query",
      data_type: "string",
      operator: "equals",
      operator_name: "Is",
      property: "product_id",
      property_name: "Product Id",
      values: productIds.map(productId => ({ raw: productId }))
    };

    const segmentationJson = ProductService.getProductBuyerSegemntationJson();
    segmentationJson.product_criteria.criteria[0] = criteria;

    ProductService.openProductBuyerModal(segmentationJson); 
  };

  return (
    <Box>
      <Text fontSize="2xl" mb={4}>Product Overview</Text>
      <PrimaryButton rightIcon={<BsFunnel/>}  title={'Filter Products'} onClick={()=>setFilterModalOpen(true)} label={'Filter Products'} />
      <FilterProductsModal isOpen={filterModalOpen} onClose={()=>setFilterModalOpen(false)}  onApplyFilters={(filters)=>{
        
        setSegmentationJson(filters);
        getProductList()}
        } />
      {showLoader ? (
        <Spinner size="xl" />
      ) : (
        <Box overflowY="auto" maxHeight="700px">
          <Table variant="simple">
            <Thead>
              <Tr>
                {ProductService?.getProductTableHeaders()?.map((header, index) => (
                  <Th key={index}>{header}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              { !!productsList.length && productsList?.map((product, index) => (
                <Tr key={index}>
                  <Td w={'100%'} display={'flex'} gap={'10px'} >
                    <Image h={'50px'} width={'50px'} src={product.image} />

                    <Link to={`/audience/products/details/${product.product_id}`} >
                    <Text color={'blue'} textDecoration={'underline'} fontWeight={'500'} > 
                    {product.title}
                    </Text> 
                    </Link>
                  </Td>
                  <Td>{product.product_id}</Td>
                  <Td>{product.product_type}</Td>
                  <Td>{product.published_at}</Td>
                  <Td>{product.updated_at}</Td>
                  <Td>{product.total_orders}</Td>
                  <Td>{product.gross_profit}</Td>
                  <Td>{product.handle}</Td>
                  <Td>{product.data_source}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}


      {/* Pagination Controls */}
      <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={setCurrentPage}>
        <PaginationContainer alignItems={'center'} justifyContent="end" mt={2}>
          <PaginationPrevious mr={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&lt;</PaginationPrevious>
          <PaginationPageGroup height={8}>
            {
              pages.map(page => (
                <PaginationPage key={`pagination_page_${page}`} page={page} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8} isDisabled={currentPage === page}></PaginationPage>
              ))
            }
          </PaginationPageGroup>
          <PaginationNext ml={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&gt;</PaginationNext>
        </PaginationContainer>
      </Pagination>
    </Box>
  );
};

export default ProductsOverview;
