import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Image,
  VStack,
  Tag,
} from "@chakra-ui/react";
import { FaMoneyBillWave, FaBoxes, FaPercent, FaTags } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { ProductService } from "./ProductService";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [productUtmPerformance, setProductUtmPerformance] = useState(null);

  const orgId = !!localStorage?.selectedOrg && JSON.parse(localStorage?.selectedOrg)?.token;

  useEffect(() => {
    // Fetch product details
    ProductService.getProductDetails(orgId, id).then((res) => {
      if (res && res.product_id) {
        const productData = { ...res };
        if (res.tags) {
          productData.tags = res.tags.split(",").map((tag) => tag.trim());
        }
        productData.average_amount_refunded =
          res.amount_refunded && res.quantity_refunded
            ? res.amount_refunded / res.quantity_refunded
            : 0;
        productData.percent_refunded =
          res.amount_refunded && res.total_orders
            ? (res.amount_refunded / res.total_orders) * 100
            : "-";

        let totalQuantityAvailable = 0;
        let variantsTotalAveragePrice = 0;
        res.variants.forEach((variant) => {
          totalQuantityAvailable += variant.inventory_quantity || 0;
          variantsTotalAveragePrice +=
            variant.revenue && variant.total_orders
              ? variant.revenue / variant.total_orders
              : 0;
        });

        productData.total_quantity_available = totalQuantityAvailable;
        productData.variants_total_average_price = variantsTotalAveragePrice;
        productData.average_order_value =
          res.total_orders > 0 ? res.revenue / res.total_orders : 0;

        setProduct(productData);
      }
    });
  }, [id]);

  const fetchProductUtmPerformance = () => {
    ProductService.getProductPerformance(id).then((res) => {
      if (res) {
        setProductUtmPerformance(res);
      }
    });
  };

  useEffect(() => {
    // fetchProductUtmPerformance();
  }, []);

  return (
    <Box p={8}>
      <Text fontSize="2xl" fontWeight="bold" mb={4}>
        Product Details
      </Text>

      {/* Product Image */}
      <Flex mb={8} alignItems={'center'} gap={'15px'} justifyContent="start">
        {product.image ? (
            <>
          <Image
            src={product.image}
            alt={product.title || "Product Image"}
            boxSize="200px"
            objectFit="cover"
            borderRadius="md"
            shadow="md"
            />
            <VStack justifyContent={'start'} alignItems={'baseline'} >

          <Text size={'2rem'} fontWeight={"500"} >{product.title}</Text>
          {/* <Text size={'0.6rem'} fontWeight={"500"} dangerouslySetInnerHTML={{__html:product.body_html|| ""}} ></Text> */}
          <Text size={'1rem'} >{product.variants.length} variants </Text>
            </VStack>
            </>
        ) : (
          <Text>No Image Available</Text>
        )}
        {/* {product?.tags?.split(',').map((tag)=>(
        <Tag>{tag}</Tag>
))} */}
      </Flex>

      {/* KPI Section */}
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        <Stat bg="blue.50" p={4} borderRadius="md" shadow="md">
          <StatLabel display="flex" alignItems="center">
            <Icon as={FaMoneyBillWave} boxSize={5} color="blue.500" mr={2} />
            Revenue
          </StatLabel>
          <StatNumber>{product?.currency?.currency} {product?.revenue?.toFixed(2) || "0.00"}</StatNumber>
        </Stat>

        <Stat bg="green.50" p={4} borderRadius="md" shadow="md">
          <StatLabel display="flex" alignItems="center">
            <Icon as={FaBoxes} boxSize={5} color="green.500" mr={2} />
            Quantity Sold
          </StatLabel>
          <StatNumber>{product?.total_orders?.toFixed(2) || 0}</StatNumber>
        </Stat>

        {/* <Stat bg="yellow.50" p={4} borderRadius="md" shadow="md">
          <StatLabel display="flex" alignItems="center">
            <Icon as={FaPercent} boxSize={5} color="yellow.500" mr={2} />
            Percent Refunded
          </StatLabel>
          <StatNumber>{product?.percent_refunded?.toFixed(2) || "0"}%</StatNumber>
        </Stat> */}

        <Stat bg="purple.50" p={4} borderRadius="md" shadow="md">
          <StatLabel display="flex" alignItems="center">
            <Icon as={FaTags} boxSize={5} color="purple.500" mr={2} />
            Tags
          </StatLabel>
          <StatNumber>
            {product.tags ? (
              product.tags.map((tag, idx) => (
                <Badge key={idx} colorScheme="purple" mr={1}>
                  {tag}
                </Badge>
              ))
            ) : (
              "N/A"
            )}
          </StatNumber>
        </Stat>
      </SimpleGrid>

      {/* Additional Product Information */}
      <Box mt={8}>
        {/* <Text fontSize="lg" fontWeight="bold" mb={4}>
          Additional Details
        </Text> */}
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          <Stat bg="cyan.50" p={4} borderRadius="md" shadow="md">
            <StatLabel>Total Quantity Available</StatLabel>
            <StatNumber>{product?.total_quantity_available?.toFixed(2) || "0"}</StatNumber>
          </Stat>

          <Stat bg="pink.50" p={4} borderRadius="md" shadow="md">
            <StatLabel>Average Price of Variants</StatLabel>
            <StatNumber>{product?.currency?.currency} {product?.variants_total_average_price?.toFixed(2) || "0.00"}</StatNumber>
          </Stat>

          <Stat bg="orange.50" p={4} borderRadius="md" shadow="md">
            <StatLabel>Average Order Value</StatLabel>
            <StatNumber>{product?.currency?.currency} {product?.average_order_value?.toFixed(2) || "0.00"}</StatNumber>
          </Stat>
        </SimpleGrid>
      </Box>

      {/* UTM Performance Section */}
      {productUtmPerformance && (
        <Box mt={8}>
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            UTM Performance
          </Text>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>Source</Th>
                <Th>Medium</Th>
                <Th>Campaign</Th>
                <Th>Performance</Th>
              </Tr>
            </Thead>
            <Tbody>
              {productUtmPerformance.map((perf, idx) => (
                <Tr key={idx}>
                  <Td>{perf.source}</Td>
                  <Td>{perf.medium}</Td>
                  <Td>{perf.campaign}</Td>
                  <Td>{perf.performance}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
        <Box overflowY="auto" maxH="400px" mt={8}>
  <Table variant="striped" colorScheme="gray">
    <Thead>
      <Tr>
        <Th>Image</Th>
        <Th>Title</Th>
        <Th>SKU</Th>
        <Th>Price</Th>
        <Th>Quantity Sold</Th>
        <Th>Revenue</Th>
        <Th>Refunded Amount</Th>
        <Th>Inventory Quantity</Th>
        <Th>Last Updated</Th>
      </Tr>
    </Thead>
    <Tbody>
      {product?.variants?.map((item) => (
        <Tr key={item.id}>
          <Td>
            <Image
              src={item.image}
              alt={item.title}
              boxSize="50px"
              objectFit="contain"
            />
          </Td>
          <Td>{item.title}</Td>
          <Td>{item.sku}</Td>
          <Td>{item.price}</Td>
          <Td>{item.quantity_sold}</Td>
          <Td>{item.revenue}</Td>
          <Td>{item.amount_refunded}</Td>
          <Td>{item.inventory_quantity}</Td>
          <Td>{new Date(item.updated_at)?.toLocaleDateString()}</Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
</Box>

    </Box>
  );
};

export default ProductDetails;
