import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Input,
  Checkbox,
  VStack,
  HStack,
  Box,
  Text,
  Flex,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import axios from "axios";
import { getAllUsersForSU, updateUserForSU, resetPasswordForSU } from "../../Services/api";

const AllUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [editedUser, setEditedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchUsers(page);
  }, [page]);

  const fetchUsers = async (pageNum, query = "") => {
    try {
      const response = await getAllUsersForSU(pageNum, 10, query);
      setUsers(response.data.items);
      setTotalPages(response.data.page_count);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleEdit = (user) => {
    setEditedUser({ ...user });
  };

  const handleSave = async () => {
    if (!editedUser) return;

    const confirmation = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, save it!",
      cancelButtonText: "Cancel",
    });

    if (confirmation.isConfirmed) {
      try {
        await updateUserForSU(editedUser);
        Swal.fire("Success!", "User updated successfully.", "success");
        setEditedUser(null);
        fetchUsers(page);
      } catch (error) {
        Swal.fire("Error!", "Failed to update user.", "error");
        console.error("Error updating user:", error);
      }
    }
  };

  const handleResetPassword = async (email) => {
    const { value: password } = await Swal.fire({
      title: "Reset Password",
      input: "password",
      inputLabel: "New Password",
      inputPlaceholder: "Enter the new password",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    });

    if (password) {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to reset the password?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "Cancel",
      });

      if (confirmation.isConfirmed) {
        try {
          await resetPasswordForSU({ email, password });
          Swal.fire("Success!", "Password reset successfully.", "success");
        } catch (error) {
          Swal.fire("Error!", "Failed to reset password.", "error");
          console.error("Error resetting password:", error);
        }
      }
    }
  };

  const handleInputChange = (field, value) => {
    if (!editedUser) return;
    setEditedUser((prev) => ({ ...prev, [field]: value }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    setPage(0); // Reset to first page when searching
    fetchUsers(0, searchTerm);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <VStack spacing={4} align="stretch">
      <Text fontSize="2xl" fontWeight="bold">
        All Users
      </Text>
      <Box>
        <HStack>
          <Input
            placeholder="Search by email or name"
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
          />
          <Button colorScheme="blue" onClick={handleSearch}>
            Search
          </Button>
        </HStack>
      </Box>
      <Box border="1px" borderColor="gray.200" borderRadius="md" p={4}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Email</Th>
              <Th>Email Verified</Th>
              <Th>Mobile No</Th>
              <Th>Allowed By Admin</Th>
              <Th>Is Super Admin</Th>
              <Th>Is Disabled</Th>
              <Th>All Bank User</Th>
              <Th>All Fetaures User</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user) => (
              <Tr key={user.email}>
                {editedUser && editedUser.email === user.email ? (
                  <>
                    <Td>
                      <Input
                        value={editedUser.first_name || ""}
                        onChange={(e) =>
                          handleInputChange("first_name", e.target.value)
                        }
                      />
                    </Td>
                    <Td>
                      <Input
                        value={editedUser.last_name || ""}
                        width="200px"
                        onChange={(e) =>
                          handleInputChange("last_name", e.target.value)
                        }
                      />
                    </Td>
                    <Td>
                      {editedUser.email}
                    </Td>
                    <Td>
                      <Checkbox
                        isChecked={editedUser.email_verified}
                        onChange={(e) =>
                          handleInputChange("email_verified", e.target.checked)
                        }
                      />
                    </Td>
                    <Td>{editedUser.mobile_no}</Td>
                    <Td>
                      <Checkbox
                        isChecked={editedUser.allowed_by_admin}
                        onChange={(e) =>
                          handleInputChange("allowed_by_admin", e.target.checked)
                        }
                      />
                    </Td>
                    <Td>
                      <Checkbox
                        isChecked={editedUser.is_super_admin}
                        onChange={(e) =>
                          handleInputChange("is_super_admin", e.target.checked)
                        }
                      />
                    </Td>
                    <Td>
                      <Checkbox
                        isChecked={editedUser.is_disabled}
                        onChange={(e) =>
                          handleInputChange("is_disabled", e.target.checked)
                        }
                      />
                    </Td>
                    <Td>
                      <Checkbox
                        isChecked={editedUser.all_bank_user}
                        onChange={(e) =>
                          handleInputChange("all_bank_user", e.target.checked)
                        }
                      />
                    </Td>
                    <Td>
                      <Checkbox
                        isChecked={editedUser.all_features_user}
                        onChange={(e) =>
                          handleInputChange("all_features_user", e.target.checked)
                        }
                      />
                    </Td>
                    <Td>
                      <Wrap spacing={2}>
                        <WrapItem>
                          <Button colorScheme="green" size="sm" onClick={handleSave}>
                            Save
                          </Button>
                        </WrapItem>
                        <WrapItem>
                          <Button
                            colorScheme="gray"
                            size="sm"
                            onClick={() => setEditedUser(null)}
                          >
                            Cancel
                          </Button>
                        </WrapItem>
                      </Wrap>
                    </Td>
                  </>
                ) : (
                  <>
                    <Td>{user.first_name}</Td>
                    <Td>{user.last_name}</Td>
                    <Td>{user.email}</Td>
                    <Td>{user.email_verified ? "Yes" : "No"}</Td>
                    <Td>{user.mobile_no}</Td>
                    <Td>{user.allowed_by_admin ? "Yes" : "No"}</Td>
                    <Td>{user.is_super_admin ? "Yes" : "No"}</Td>
                    <Td>{user.is_disabled ? "Yes" : "No"}</Td>
                    <Td>{user.all_bank_user ? "Yes" : "No"}</Td>
                    <Td>{user.all_features_user ? "Yes" : "No"}</Td>
                    <Td>
                      <Wrap spacing={2}>
                        <WrapItem>
                          <Button
                            colorScheme="blue"
                            size="sm"
                            onClick={() => handleEdit(user)}
                          >
                            Edit
                          </Button>
                        </WrapItem>
                        <WrapItem>
                          <Button
                            colorScheme="red"
                            size="sm"
                            onClick={() => handleResetPassword(user.email)}
                          >
                            Reset Password
                          </Button>
                        </WrapItem>
                      </Wrap>
                    </Td>
                  </>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <Flex justify="center">
        {[...Array(totalPages)].map((_, i) => (
          <Button
            key={i}
            size="sm"
            colorScheme={i === page ? "blue" : "gray"}
            onClick={() => setPage(i)}
          >
            {i + 1}
          </Button>
        ))}
      </Flex>
    </VStack>
  );
};

export default AllUsersPage;
