import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Text,
  Flex,
  FormControl,
  FormLabel,
  Spinner,
  Icon,
} from '@chakra-ui/react';
import Select from '../../components/themeComponents/react-chakra-select';
import { useQuery } from 'react-query';
import { fetchTemplate, getSmsGatways } from '../../Services/api';
import {
  prepareMultiOrgTemplates,
  prepareTemplateList,
  prepareGatewayList,
  smtpTypes,
} from '../../_helpers/data_helper';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import ABTestMobilePreview from '../Campaigns/components/ABTestMobilePreview';
import _ from 'lodash';

function TemplateSelectionSms({
  selectedChannel,
  templateQuery,
  orgToken,
  formik,
  setTemplatesData,
  templatesData,
  setSelectedGatewayUuid,
  selectedGatewayUuid,
  location,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [template, setTemplate] = useState({});
  
  const gatewayQuery = useQuery(['gateways'], async () => {
    let obj = {};
    for (const token of formik.values.orgList) {
      obj[token] = await getSmsGatways(token);
    }
    return obj;
  });

  const selectTempData = (e) => {
    formik.setFieldValue('selectedTemp', e.uuid);
    formik.setFieldValue('selectedTempName', e.label);
    if (selectedChannel === 'email') {
      formik.setFieldValue('tempBody', e.body);
    } else {
      selectTemplate(e);
    }
  };

  const selectTemplate = (e) => {
    const selectedTemplate = templateQuery?.data?.find(
      (template) => template.uuid === e.uuid
    );
    setTemplate(selectedTemplate);
    setTemplatesData((prevTemplatesData) => ({
      ...prevTemplatesData,
      [orgToken]: e,
    }));
  };

  useEffect(() => {
    if (location?.state?.data) {
        let obj = {}
      location.state.data.forEach((org) => {
        
        setSelectedGatewayUuid((prev) => ({
          ...prev,
          [org.orgToken]: org.selectedGateway,
        }));

        obj[org.orgToken] =  _.filter(
            prepareMultiOrgTemplates(
              templateQuery?.data,
              formik?.values?.selectedChannel
            ),
            { uuid: org.selectedTemp }
          )[0] 
          
        // const data = prepareMultiOrgTemplates([selectedTemplate], 'sms');
        
      });
      setTemplatesData((prevTemplatesData) => ({
        ...prevTemplatesData,
        [orgToken]: obj[orgToken] ,
      }));
    }
  }, [location, templateQuery.data]);

  return (
    <>
      <Box w="100%" m="0 auto" mt={5} p={3} id="screenshot" bg="white">
        <Text color="heading" as="b">
          Template Information
        </Text>
        <Flex gap={12} mt={4}>
          <Box p={3} bg="white" w="60%">
            {/* <FormControl>
              {formik.values.selectedChannel === 'sms' && (
                <>
                  <FormLabel fontWeight="600" color="inputLabel">
                    Select Provider
                  </FormLabel>
                  {gatewayQuery.isLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    gatewayQuery?.data[orgToken]?.data && (
                      <Select
                        value={_.filter(
                          prepareGatewayList(
                            gatewayQuery?.data[orgToken]?.data?.gatewayList
                          ),
                          { value: selectedGatewayUuid[orgToken] }
                        )}
                        onChange={(e) =>
                          setSelectedGatewayUuid((prev) => ({
                            ...prev,
                            [orgToken]: e.value,
                          }))
                        }
                        options={prepareGatewayList(
                          gatewayQuery?.data[orgToken]?.data?.gatewayList
                        )}
                      />
                    )
                  )}
                </>
              )}
            </FormControl> */}
            <FormControl>
              <FormLabel color="inputLabel">Template</FormLabel>
              {templateQuery.isLoading ? (
                <Spinner size="sm" />
              ) : (
                <Select
                  value={_.filter(
                    prepareMultiOrgTemplates(
                      templateQuery?.data,
                      formik?.values?.selectedChannel
                    ),
                    { uuid: templatesData[orgToken]?.uuid }
                  )}
                  options={prepareMultiOrgTemplates(
                    templateQuery?.data,
                    formik?.values?.selectedChannel
                  )}
                  onChange={selectTempData}
                />
              )}
              {!!templatesData[orgToken] && (
                <Box
                  cursor="pointer"
                  float="right"
                  title="Preview"
                  onClick={() => setIsOpen(true)}
                >
                  <Icon
                    as={
                      !!formik.values.selectedTemp ? FaEye : FaEyeSlash
                    }
                    cursor="pointer"
                    mr={1}
                  />
                </Box>
              )}
            </FormControl>
            
          </Box>
          <Box>
            <ABTestMobilePreview
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              channel={formik.values.selectedChannel}
              message={template?.message}
              attachment={template?.attachment}
              sample_body={template?.sample_body}
              buttons={template?.buttons}
            />
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export default TemplateSelectionSms;
