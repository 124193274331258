import { Box, Icon, Input, InputGroup, InputLeftElement, Table, TableContainer, Text, useDisclosure, Select, FormControl, FormLabel, useToken, Flex, HStack } from '@chakra-ui/react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import CampaignDraftDrawer from '../Campaigns/CampaignDraftDrawer'
import DateRangeSelection from '../../components/DateRangeSelection'
import moment from 'moment'
import TableHead from '../../Pages/Campaigns/components/TableHead'
import CampaignTable from '../../Pages/Campaigns/components/CampaignTable'
import { useQuery } from 'react-query'
import { getBroadcastData, prepareCampaignListData, prepareCampaignListDataAllBanks } from '../../_helpers/data_helper'
import { getBroadcastHeaders, getCampaigns, getCampaignsAllBanks } from '../../Services/api'
import * as _ from 'lodash'

import CampaignFilter from '../../Pages/Campaigns/components/CampaignFilter'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from '@ajna/pagination'
import hexToRgba from 'hex-to-rgba'
import { OvalSpinner } from '../../components/Loaders'
import OutlinedButton from '../../components/themeComponents/OutlinedButton'
import store from '../../store/store'
import AllBanksOverviewTable from './AllBanksOverviewTable'
import DownloadReportModal from '../Campaigns/DownloadReportModal'


function CampaignList() {
  const [org, setOrg] = useState("");
  const [orgList, setOrgList] = useState([])
  const [mainColor] = useToken('colors', ['primary.main'])
  const [dateRange, setDateRange] = useState({ startDate: moment().subtract(30, "days"), endDate: moment() });
  const [totalCount, setTotalCount] = useState(0)
  const [selectChannel, setSelectedChannel] = useState("")
  const [campaignDataRaw, setCampaignDataRaw] = useState([])
  const [broadcastHeaders, setBroadcastHeaders] = useState({})
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [campaignFilter, setCampaignFilter] = useState({
    campaignName: '',
    channel: '',
    status: 'ALL'
  });

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({ total: totalCount, initialState: { currentPage: 1, pageSize: 5, isDisabled: false }, limits: { outer: 1, inner: 1 } })
  const [isDownloadReportModalOpen, setIsDownloadReportModalOpen] = useState(false)
  const campaignQuery = useQuery(
    ['campaigns', dateRange, campaignFilter, currentPage],
    {
      queryFn: async ({ signal }) => {
        try {
          // Pass the signal to your API calls to allow them to be aborted
          const res = await getCampaignsAllBanks(org, campaignFilter, dateRange, currentPage, { signal });
          const broadcastHeaders = await getBroadcastHeaders({ signal });
  
          setTotalCount(res?.data?.count);
          setBroadcastHeaders(broadcastHeaders?.data);
  
          if (campaignFilter.channel === 'Email' || campaignFilter.channel === '') {
            setCampaignFilter({ channel: "Email" });
          }
  
          setCampaignDataRaw(res?.data?.data);
  
          return prepareCampaignListDataAllBanks(
            res.data.data,
            broadcastHeaders.data,
            campaignFilter
          );
        } catch (error) {
          if (error.name === "AbortError") {
            console.log("Previous request aborted");
          } else {
            throw error;
          }
        }
      },
      onError: (error) => {
        console.error("Error fetching campaign data:", error);
      },
    }
  );
  
  console.log(campaignQuery, "campaignQuery")

  const tableRef = useRef();
  const menuList = useRef();
  // if (!!document.querySelector('.css-1km9ei0')) {
  //   document.querySelector('.css-1km9ei0').addEventListener('scroll', (e) => { 
  //     console.log(menuList.current.id)
  //     if(menuList?.current.offsetParent.style.visibility=='visible'){
  //       menuList.current.offsetParent.style.visibility = 'hidden'
  //     }
  //     // menuList.current.blur()

  //     // menuList.current.attributes.style.value = menuList?.current?.attributes?.style.value.replace('visible','hidden')

  //   })
  // }

  useEffect(() => {

    setOrg(store.getState().auth.selectedOrg.token)
    setOrgList(store.getState().org.orgList)
  }, [])
  useEffect(() => {
    campaignQuery.refetch()
  }, [org])
  function onDateChange(date) {
    setDateRange({ startDate: date.startDate, endDate: date.endDate })
  }
  return (
    <Fragment>
      <Box zIndex={99} display={'flex'} alignItems={'center'} justifyContent={'space-between'} my={4}>
        {/*<Text fontSize={'1vw'} as={'b'}>Campaigns</Text>*/}
      <DateRangeSelection dateRange={dateRange} onDateChange={onDateChange} isLoading={campaignQuery.isLoading}></DateRangeSelection>
        <OutlinedButton label={'Create Campaign'} onClick={() => navigate('/all-banks/create')}></OutlinedButton>
        {/* <HStack width={'15%'} >
        <Text>
        Create
        </Text>
        
        <Select  onClick={(e)=>{ broadcastTypes[e.target.value]() }} >
        { ["AB TESTING", "BROADCAST"].map(campaign=>(
          <option>{campaign}</option> 
          ))
          }
          </Select>
          </HStack> */}
      </Box>
      {/* <FormControl my={2} maxW={'350px'}>
                <FormLabel color={'inputLabel'} fontSize={'1vw'} fontWeight={'600'}>Organization</FormLabel>
                <Select onChange={(e) => {
                  
                    setOrg(e.target.value);
                
                }}>
                  
                  {
                    orgList.map(o => {
                      return <option value={o.token}>{o.name}</option>
                    })
                  }
                </Select>
              </FormControl> */}
      <CampaignFilter downloadReport={() => setIsDownloadReportModalOpen(true)} channels={broadcastHeaders} onFilter={(dt) => setCampaignFilter(dt)} />
      <TableContainer bg={'white'} maxH={'60vh'} w={'99%'} overflowY={'auto'} mt={0} >
        {
          campaignQuery.isLoading && <Flex justifyContent={'center'} alignItems={'center'} h={'200px'}><OvalSpinner width={50} height={50}></OvalSpinner></Flex>
        }
        <Table >
          {
          !!campaignQuery?.data?.data?.length > 0 ? (
            <>
              <Fragment>
                <TableHead headersProps={['Campaign Name', 'Status', 'Created At', "Audit Logs", "Actions"]}></TableHead>
                <AllBanksOverviewTable
                  selectedChannel={campaignFilter.channel}
                  broadcastHeaders={broadcastHeaders}
                  campaignDataRaw={campaignDataRaw}
                  tableRef={tableRef}
                  menuList={menuList}
                  campaignData={campaignQuery.data?.data}>
                </AllBanksOverviewTable>
              </Fragment>
            </>
          ) : (
            <Flex direction="column" justifyContent="center" alignItems="center" py={10}>
              <Text fontSize="lg" fontWeight="bold" color="gray.600">
                No campaigns found.
              </Text>
              <Text fontSize="sm" color="gray.500" mt={2}>
                Start creating campaigns now.
              </Text>
              <PrimaryButton
                label="Create Campaign Now"
                mt={4}
                onClick={() => navigate('/all-banks/create')}
                size="md"
              />
            </Flex>
          )

          }
        </Table>

      </TableContainer>

      {
        !campaignQuery.isLoading && !!campaignQuery?.data?.data?.length > 0 && <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={setCurrentPage} >
          <PaginationContainer alignItems={'center'} justifyContent="end" mt={2}>
            <PaginationPrevious mr={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&lt;</PaginationPrevious>
            <PaginationPageGroup height={8}  >
              {
                pages.map(page => ( 
                  <PaginationPage key={`pagination_page_${page}`} page={page} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8} isDisabled={currentPage === page}></PaginationPage>
                ))
              }
            </PaginationPageGroup>
            <PaginationNext ml={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&gt;</PaginationNext>
          </PaginationContainer>
        </Pagination>
      }
      <DownloadReportModal dateRange={dateRange} channel={campaignFilter.channel} campaignFilter={campaignFilter} isOpen={isDownloadReportModalOpen} onClose={() => setIsDownloadReportModalOpen(false)} />
      <CampaignDraftDrawer isOpen={isOpen} onClose={onClose} orgToken={org}></CampaignDraftDrawer>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    ...state,
    org: state.auth.selectedOrg
  }
}
export default connect(mapStateToProps)(CampaignList)
