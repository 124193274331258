import React, { useEffect, useRef, useState } from 'react'
import { Box, Text, Flex, FormControl, FormLabel, AspectRatio, Image, FormHelperText, Icon, useDisclosure } from '@chakra-ui/react'
import Select from '../../components/themeComponents/react-chakra-select'
import { DeviceFrameset } from 'react-device-frameset'
import { useQuery } from 'react-query'
import { fetchTemplate } from '../../Services/api'
// import { prepareTemplateList, smtpTypes } from '../../../_helpers/data_helper'
import { prepareMultiOrgTemplates, prepareTemplateList,smtpTypes } from '../../_helpers/data_helper'

import { BsBox2Fill } from 'react-icons/bs'
import * as htmltoImage from "html-to-image"
import html2canvas from 'html2canvas'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import TemplatePreviewModal from '../Campaigns/components/TemplatePreviewModal'
import _ from "lodash";
import MobilePreview from '../Campaigns/components/MobilePreview'
import ABTestMobilePreview from '../Campaigns/components/ABTestMobilePreview'


function TemplateSelectionWhatsapp({ selectedChannel,templateQuery, orgToken, formik, setTemplatesData, templatesData , location}) {
    // const { isOpen, onClose, onOpen } = useDisclosure()
    const [isPerviewGenerating, setIsPreviewGenerating] = useState(false)
    const [template, setTemplate] = useState({})
    const tempBodyRef = useRef(null)
    // const templateQuery = useQuery(['fetchTemplate'], {
    //     queryFn: async () => {
    //         let res = await fetchTemplate(selectedChannel, orgToken);
    //         return prepareMultiOrgTemplates(selectedChannel, res.data)
    //     }
    // })
    // console.log(templateQuery,"templateQuery")
    const [isOpen,setisOpen] = useState(false);
    
    async function generateEmailPreview() {
        setIsPreviewGenerating(true);
        try {
            const tempBody = tempBodyRef.current;
            const canvas = await htmltoImage.toPng(tempBody);
            // const dataUrl = canvas.toDataURL('image/png');
            formik.setFieldValue('tempImg', canvas)
            setIsPreviewGenerating(false);
        } catch (error) {
            console.log('Error in generating preview', error)
        }
    }
    const selectTempData = (e) => {
        console.log('on temp select', e)
        formik.setFieldValue("selectedTemp", e.uuid);
        formik.setFieldValue("selectedTempName", e.label);
        if (selectedChannel === 'email') {
            formik.setFieldValue('tempBody', e.body)
            // generateEmailPreview(e.body)
        }
        if (selectedChannel === 'whatsapp') {
            selectTemplate(e)
        }
        // formik.setFieldValue("tempBody", e.body)
    }
    
    const selectTemplate = (e) => {
        setTemplate(templateQuery.data.filter(template => template.uuid == e.uuid))
        setTemplatesData((prevTemplatesData) => ({
            ...prevTemplatesData,
            [orgToken]: e
        ,
        }));
        console.log(templatesData)
    }
    useEffect(()=>{
        if(!!location?.state?.data){
            
            location?.state?.data?.forEach((org)=>{
                if(orgToken == org.orgToken){

                    setTemplate(templateQuery.data.filter(template => template?.uuid == org?.selectedTemp))
                    let data = prepareMultiOrgTemplates(templateQuery.data.filter(template => template?.uuid == org?.selectedTemp),"whatsapp")
                    
                    setTemplatesData((prevTemplatesData) => ({
                        ...prevTemplatesData,
                        [org.orgToken]: data[0]
                        ,
                    }));
                }
            })
            console.log(templatesData)
        }
    },[])
    return (

        <>
        <Box w={'100%'} m={'0 auto'} mt={5} p={3} id='screenshot' bg={'white'}>
            <Text color={'heading'} as={'b'}>Template Information </Text>
            <Flex gap={12} mt={4}>
                <Box p={3} bg={'white'} w={'60%'} >
                    <FormControl>
                        <FormLabel color={'inputLabel'}>Template</FormLabel>
                        <Select 
                        value={ !_.isEmpty(templatesData[orgToken]) && !_.isEmpty(templatesData[orgToken]?.label) && _.filter(prepareMultiOrgTemplates(templateQuery?.data,formik.values.selectedChannel),{uuid:templatesData[orgToken].uuid})}
                        options={prepareMultiOrgTemplates(templateQuery?.data,formik.values.selectedChannel)} onChange={selectTempData}></Select>
                        {
                            !!templatesData[orgToken] && <Box float={'right'} title='Preview' onClick={()=> setisOpen(true)} cursor={'pointer'}>
                                <Icon as={!!formik.values.selectedTemp ? FaEye : FaEyeSlash}  cursor={'pointer'} mr={1}></Icon>
                            </Box>
                        }

                    </FormControl>
                    
                </Box>

            <Box>
        {
              <ABTestMobilePreview   isOpen={isOpen} onClose={()=>{setisOpen(false)}} channel={formik.values.selectedChannel} message={template[0]?.message} attachment={template[0]?.attachment} sample_body={template[0]?.sample_body} buttons={template[0]?.buttons}  />
        }
        </Box>

            </Flex>
        </Box>
        

        </>
    )
}

export default TemplateSelectionWhatsapp
