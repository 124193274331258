import { Alert, AlertIcon, FormLabel, HStack, Icon, Select, Text } from '@chakra-ui/react'
import React, { Fragment } from 'react'
import { getTemplatesQuery } from '../helpers/FlowQueries'
import { useQuery } from 'react-query'
import _, { isError } from 'lodash'
import FlowEditService from '../helpers/FlowEditService'
import { prepareFlowActionTemplatesList } from '../../../_helpers/data_helper'
import { Link } from 'react-router-dom'
import { BiRefresh } from 'react-icons/bi'

export default function InspectorForAction({ formData, selectedElement, updateFormData, validateFormData }) {
    //const templateQuery = getTemplatesQuery(useQuery, selectedElement.actionType, selectedElement.templatesFetchUrl);
    const fetchTemplates = async (path) => {

        let res = await FlowEditService.getTemplatesForAction(path);
        return prepareFlowActionTemplatesList(res.data)
    }
    const { data, isLoading, error } = useQuery(selectedElement.actionType, () => {
        return fetchTemplates(selectedElement.templatesFetchUrl);
    })
    const selectTemplate = (e) => {
        let tenmpFormData = { ...formData };
        tenmpFormData[selectedElement.id].action.templateUUID = e.target.value;
        updateFormData(tenmpFormData);
        validateFormData("beginsWith")
    }
    const createTemplateLinks = {
        email: '/email/edit',
        whatsapp: '/whatsapp/create',
        sms: '/sms/create',
        push: '/push/list'
    }
    if (!!isLoading) return <Text>Loading....</Text>
    if (!!error) return <Text>Error....</Text>
    return (
        <Fragment>
            {
                !!data && !data?.hasAccess && <Alert status='warning'>
                    <AlertIcon></AlertIcon>
                    You do not have access to this feature. To get this feature please contact us at info@orbisdata.ai
                </Alert>
            }


            {
                !!data?.templates && !!data.hasAccess && !!data.templates.length && <Fragment>
                    <FormLabel color={'inputLabel'} mt={2}>Select Template</FormLabel>
                    <Select mt={2} placeholder='Select' value={formData[selectedElement.id].action.templateUUID} onChange={selectTemplate}>
                        {
                            data.templates.map(template => (
                                <option value={template.uuid}>{template.name}</option>
                            ))
                        }
                    </Select>

                </Fragment>

            }

            {
                !!data?.templates && !!data.hasAccess && !data.templates?.length && <Fragment>
                    <Alert status='warning'>
                        <AlertIcon></AlertIcon>
                        You do not have any saved templates.
                    </Alert>
                </Fragment>
            }
            <HStack w={'100%'} justifyContent={'space-between'} >
                <Link target='_blank' to={createTemplateLinks[selectedElement.actionType]}>
                    <Text textDecoration={'underline'} cursor={'pointer'} >
                        Create Template +
                    </Text>
                </Link>
                <Text onClick={() => fetchTemplates(selectedElement.templatesFetchUrl)} textDecoration={'underline'} cursor={'pointer'} >
                    Refresh
                    <Icon as ={BiRefresh}/>
                </Text>
            </HStack>
        </Fragment>

    )

}
