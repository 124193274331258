import React, { useState, useEffect } from 'react';
import { 
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Input,
  HStack,
  FormLabel,
  Box,
  Spinner
} from '@chakra-ui/react';
import Select from '../../components/themeComponents/react-chakra-select';
import { prepareEmailProviderData, prepareOrgList } from '../../_helpers/data_helper';
import { fetchEmailChannels, getEmailConf, getOrgList } from '../../Services/api';
import moment from "moment";
import * as _ from 'lodash';
import TableHead from '../Campaigns/components/TableHead';

export default function OrgAndConfig({ formik, selectedOrgList, setSelectedOrgList, selectedSmtp, setSelectedSmtp, emailConfiguration, setEmailConfiguration, location }) {
  const [orgList, setOrgList] = useState([]);
  const [emailSetting, setEmailSetting] = useState([]);
  const [loadingOrgList, setLoadingOrgList] = useState(false);
  const [loadingConfig, setLoadingConfig] = useState(false);

  const fetchEmailProviders = async (orgToken) => {
    try {
      const { data } = await fetchEmailChannels(orgToken);
      const providers = data?.data || [];
      setEmailSetting(providers);

      if (data?.default_promotional_uuid) {
        updateSmtpSettings(data.default_promotional_uuid, orgToken);
      }

      if (location?.state?.data) {
        let obj = {};
        location.state.data.forEach((org) => {
          obj[org.orgToken] = org.selectedSmtpUuid;
          setSelectedSmtp(obj);
        });
      }
      return providers;
    } catch (error) {
      console.error('Error fetching email providers:', error);
      return [];
    }
  };

  const fetchEmailConfig = async (token) => {
    setLoadingConfig(true);
    try {
      const { data } = await getEmailConf(token);
      const emailSettings = await fetchEmailProviders(token);

      setEmailConfiguration((prevConfig) => [
        ...prevConfig.filter((config) => config.orgToken !== token),
        { ...data, emailSettings, orgToken: token },
      ]);
    } catch (err) {
      console.error('Error fetching email configuration:', err);
    } finally {
      setLoadingConfig(false);
    }
  };

  const loadOrgList = async () => {
    setLoadingOrgList(true);
    try {
      const { data } = await getOrgList();
      setOrgList(data || []);
    } catch (err) {
      console.error('Error fetching organization list:', err);
    } finally {
      setLoadingOrgList(false);
    }
  };

  const handleOrgSelectionChange = (selectedOptions) => {
    setSelectedOrgList(selectedOptions);
    const selectedTokens = selectedOptions.map((option) => option.value);
    formik.setFieldValue('orgList', selectedTokens);

    setEmailConfiguration((prevConfig) =>
      prevConfig.filter((config) => selectedTokens.includes(config.orgToken))
    );

    const newTokens = selectedTokens.filter(
      (token) => !emailConfiguration.some((config) => config.orgToken === token)
    );

    newTokens.forEach((token) => fetchEmailConfig(token));
  };

  const updateSmtpSettings = (value, org) => {
    setSelectedSmtp((prev) => ({ ...prev, [org]: value }));
  };

  useEffect(() => {
    loadOrgList();
    if (location?.state?.data) {
      formik.setFieldValue("selectedChannel", location?.state?.data[0]?.selectedChannel);
    }
    if (selectedOrgList.length > 0) {
      const selectedTokens = selectedOrgList.map((org) => org.value);
      formik.setFieldValue('orgList', selectedTokens);
      selectedTokens.forEach((token) => fetchEmailConfig(token));
    }
  }, [selectedOrgList]);

  useEffect(() => {
    if (formik.values.selectedChannel) {
      formik.setFieldValue(
        'campaignName',
        `${_.capitalize(formik.values.selectedChannel)} - ${moment().format('lll')}`
      );
    }
  }, [formik.values.selectedChannel]);

  const channels = [
    { label: 'Email', value: 'email' },
    { label: 'Whatsapp', value: 'whatsapp' },
    { label: 'SMS', value: 'sms' },
  ];

  return (
    <Box maxW="1100px">
      <Text fontSize="1vw">Channel</Text>
      <Select
        options={channels}
        value={_.find(channels, { value: formik.values.selectedChannel })}
        onChange={(e) => formik.setFieldValue('selectedChannel', e.value)}
      />

      <Box mt="1rem">
        <form>
          <Text>Campaign Name</Text>
          <Input
            type="text"
            name="campaignName"
            value={formik.values.campaignName}
            onChange={formik.handleChange}
          />

          <Text>Bank</Text>
          {loadingOrgList ? (
            <Spinner size="sm" />
          ) : (
            <Select
              isMulti
              options={prepareOrgList(orgList)}
              onChange={handleOrgSelectionChange}
              value={selectedOrgList}
              closeMenuOnSelect={false}
              colorScheme="brand"
              isClearable={false}
            />
          )}
        </form>
      </Box>

      {formik.values.selectedChannel === 'email' && (
        <>
          <FormLabel>Configuration</FormLabel>
          <VStack bg={"#fff"} w="100%" p="1rem" borderRadius="md" boxShadow="md">
            {loadingConfig ? (
              <Spinner size="lg" />
            ) : emailConfiguration.length > 0 ? (
              <Table variant="simple" size="sm" w="100%">
                <TableHead headersProps={["Bank", "From", "Configuration Type"]} />
                <Tbody>
                  {selectedOrgList.map((org, i) => {
                    const config = emailConfiguration.find(
                      (conf) => conf.orgToken === org.value
                    );
                    return (
                      <Tr key={i}>
                        <Td>{config?.orgName || org?.data?.name || "N/A"}</Td>
                        <Td>{config?.from?.split("<")[1]?.split(">")[0] || "N/A"}</Td>
                        <Td w={'45%'}>
                          {!_.isEmpty(prepareEmailProviderData(config?.emailSettings)) ? (
                            <Select
                              value={_.find(
                                prepareEmailProviderData(config?.emailSettings || []),
                                { value: selectedSmtp[org.value] }
                              )}
                              options={prepareEmailProviderData(config?.emailSettings || [])}
                              onChange={(e) => updateSmtpSettings(e.value, org.value)}
                            />
                          ) : (
                            <Text>N/A</Text>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            ) : (
              <Text>No configurations available</Text>
            )}
          </VStack>
        </>
      )}
    </Box>
  );
}
