import { Box, Text, Input, FormLabel, Icon, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import store from '../../store/store';
import TemplateSelection from './TemplateSelection';
import TemplateSelectionWhatsapp from './TemplateSelectionWhatsapp';
import { fetchTemplateTG } from '../../Services/api';
import { useQuery } from 'react-query';
import TemplateSelectionSms from './TemplateSelectionSms';
import SendTestModal from '../../components/SendTestModal';
import { BsSend } from 'react-icons/bs';

export default function TemplateSelectionTG({
  formik,
  templatesData,
  setTemplatesData,
  setSelectedGatewayUuid,
  selectedGatewayUuid,
  selectedSmtpUuid,
  location,
}) {
  const [orgList, setOrgList] = useState(store.getState()?.org?.orgList);
  const [isSendTestModalOpen, setIsSendTestModalOpen] = useState(false);

  const templateQuery = useQuery(['fetchTemplate'], {
    queryFn: async () => {
      let res = await fetchTemplateTG(formik.values.selectedChannel, formik.values.orgList);
      return res.data;
    },
    enabled: true,
  });

  useEffect(() => {
    templateQuery.refetch();
    setOrgList(
      orgList.filter((org) => {
        return formik.values.orgList.includes(org.token);
      })
    );
  }, []);

  return (
    <>
      {templateQuery.isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" h="100%">
          <Spinner size="xl" color="teal.500" />
        </Box>
      ) : (
        !!templateQuery?.data?.length &&
        templateQuery?.data.map((e, i) => (
          <Box key={i}>
            <Text>
              {formik.values.selectedChannel === 'email' && (
                <>
                  <Box bg="#fff" p="2rem">
                    {i === 0 && (
                      <>
                        <FormLabel>Common Subject Line</FormLabel>
                        <Input
                          w="350px"
                          name="subjectLine"
                          type="text"
                          value={formik.values?.subjectLine}
                          onChange={(e) => {
                            formik.setFieldValue('subjectLine', e?.target?.value);
                          }}
                        />
                      </>
                    )}
                    {i === 0 && (
                      <Text
                        mb="15px"
                        float="right"
                        cursor="pointer"
                        textDecoration="underline"
                        onClick={() => {
                          setIsSendTestModalOpen(true);
                        }}
                      >
                        Send Test Communication
                        <Icon as={BsSend} cursor="pointer" title={`Send Test ${formik.values.selectedChannel}`} ml="10px" />
                      </Text>
                    )}
                    <TemplateSelection
                      location={location}
                      templateQueryRefetch={templateQuery.refetch}
                      templatesData={templatesData}
                      setTemplatesData={setTemplatesData}
                      templateQuery={e}
                      selectedChannel={formik.values.selectedChannel}
                      orgToken={e.org_token}
                      formik={formik}
                    />
                    <SendTestModal
                      formik={formik}
                      selectedSmtpUuid={selectedSmtpUuid}
                      templateData={templatesData}
                      orgToken={e.org_token}
                      uuid={templatesData[e.org_token]}
                      isOpen={isSendTestModalOpen}
                      onClose={() => setIsSendTestModalOpen(false)}
                      channel={formik.values.selectedChannel}
                    />
                  </Box>
                </>
              )}
              {formik.values.selectedChannel === 'whatsapp' && (
                <>
                  <TemplateSelectionWhatsapp
                    location={location}
                    templatesData={templatesData}
                    setTemplatesData={setTemplatesData}
                    templateQuery={e}
                    selectedChannel={formik.values.selectedChannel}
                    orgToken={e.org_token}
                    formik={formik}
                  />
                  {i === 0 && (
                    <Text
                      mb="15px"
                      float="right"
                      cursor="pointer"
                      textDecoration="underline"
                      onClick={() => {
                        setIsSendTestModalOpen(true);
                      }}
                    >
                      Send Test Communication
                      <Icon as={BsSend} cursor="pointer" title={`Send Test ${formik.values.selectedChannel}`} ml="10px" />
                    </Text>
                  )}
                  <SendTestModal
                    formik={formik}
                    selectedSmtpUuid={selectedSmtpUuid}
                    templateData={templatesData}
                    orgToken={e.org_token}
                    // uuid={templatesData[e.org_token]}
                    isOpen={isSendTestModalOpen}
                    onClose={() => setIsSendTestModalOpen(false)}
                    channel={formik.values.selectedChannel}
                  />
                </>
              )}
              {formik.values.selectedChannel === 'sms' && (
                <>
                 {i === 0 && (
                    <Text
                      mb="15px"
                      float="right"
                      cursor="pointer"
                      textDecoration="underline"
                      onClick={() => {
                        setIsSendTestModalOpen(true);
                      }}
                    >
                      Send Test Communication
                      <Icon as={BsSend} cursor="pointer" title={`Send Test ${formik.values.selectedChannel}`} ml="10px" />
                    </Text>
                  )}
                  <TemplateSelectionSms
                    selectedGatewayUuid={selectedGatewayUuid}
                    setSelectedGatewayUuid={setSelectedGatewayUuid}
                    templatesData={templatesData}
                    setTemplatesData={setTemplatesData}
                    templateQuery={e}
                    selectedChannel={formik.values.selectedChannel}
                    orgToken={e.org_token}
                    formik={formik}
                    location={location}
                  />
                 
                  <SendTestModal
                    formik={formik}
                    selectedSmtpUuid={selectedSmtpUuid}
                    templateData={templatesData}
                    orgToken={e.org_token}
                    // uuid={templatesData[e.org_token]?.uuid}
                    isOpen={isSendTestModalOpen}
                    onClose={() => setIsSendTestModalOpen(false)}
                    channel={formik.values.selectedChannel}
                  />
                </>
              )}
            </Text>
          </Box>
        ))
      )}
    </>
  );
}

