import React from 'react'
import store from '../../store/store'

export default function Conversations() {
   let localStorageData = localStorage.getItem("token") || '{}';
    let tokenData = JSON.parse(localStorageData);
        // let decodedToken = extractUserDataFromJWT(JSON.parse(token) )
  let selectedOrg = localStorage.getItem("selectedOrg");
    // Ensure the token is URL-encoded for safe inclusion in the query string
    //const encodedToken = encodeURIComponent(bearerToken);
    const orgToken = store.getState().auth?.selectedOrg?.token;
  let path = `/conversations#/users/whatsapp`
    path = encodeURIComponent(path);
  return (
  <iframe
        src={`${process.env.REACT_APP_IFRAME_AUTO_LOGIN_ENDPOINT}/conversations?orgToken=${orgToken}&token=${tokenData.jwt}`}
        height={'100%'}
        width={'100%'}
      ></iframe>
  )
}
