import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  VStack,
  Text,
} from '@chakra-ui/react';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../Services/api';

const ResetPassword = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(4, 'Password must be at least 4 characters long')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit: async (values) => {
      setErrorMessage(""); // Clear any existing error messages
      values['uuid'] = window.location.pathname.split('/')[3];
      try {
        const resp = await resetPassword(values);
        if (resp.status === 200) {
          navigate('/auth/login');
        } else {
          setErrorMessage(resp.data.message || "An unexpected error occurred.");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data.reset) {
            setErrorMessage(error.response.data.message + " <u><b><a href = '/auth/forgot-password'>Try again</a></b></u>");
          } else {
            setErrorMessage(error.response.data.message);
          }
        } else {
          setErrorMessage("<p>An unexpected error occurred.</p>");
        }
      }
    },
  });

  return (
    <Box maxW={'md'} minW={'xl'} boxShadow={'base'} borderRadius={'xl'} bg={'white'} px={5} py={10}>
      <Text fontSize={'2xl'} fontWeight={'bold'} color={'primary.main'}>
        Welcome Back
      </Text>
      <Text fontSize={'md'} color={'light'} mb={4}>
        Enter your new password.
      </Text>
      <form onSubmit={formik.handleSubmit}>
        <VStack spacing={4}>
          <FormControl
            id="password"
            isInvalid={formik.touched.password && !!formik.errors.password}
          >
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              value={formik.values.password}
              onChange={(e) => {
                formik.handleChange(e);
                setErrorMessage("");
              }}
              onBlur={formik.handleBlur}
              placeholder="Enter your password"
            />
            <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
          </FormControl>

          <FormControl
            id="confirmPassword"
            isInvalid={
              formik.touched.confirmPassword &&
              !!formik.errors.confirmPassword
            }
          >
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={(e) => {
                formik.handleChange(e);
                setErrorMessage("");
              }}
              onBlur={formik.handleBlur}
              placeholder="Confirm your password"
            />
            <FormErrorMessage>{formik.errors.confirmPassword}</FormErrorMessage>
          </FormControl>

          {errorMessage && (
            <Text color="red.500" fontSize="sm" dangerouslySetInnerHTML={{ __html: errorMessage }} />
          )}

          <PrimaryButton label={'Set Password'} type="submit" colorScheme="blue" width="full" />
        </VStack>
      </form>
    </Box>
  );
};

export default ResetPassword;
