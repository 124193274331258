import React, { useState, useEffect } from 'react'
import { Icon, Tbody, Td, Tr, Box, Flex, Text, Badge, Menu, MenuButton, MenuList, MenuItem, useDisclosure, useToast } from '@chakra-ui/react'
// import CampaignWisePerformance from './CampaignWisePerformance'
import { BsThreeDotsVertical } from "react-icons/bs";
import { channels } from '../../_helpers/data_helper';
import moment from 'moment-timezone';
// import Engagement from './Engagement';
// import { useNavigate } from 'react-router-dom';
// import CampaignDetailsModal from './CampaignDetailsModal';
import { useNavigate } from 'react-router-dom';
import { deleteCampaign, publishCampaign } from '../../Services/api';
import store from '../../store/store';
import * as _ from 'lodash'
import AllBanksOverview from './AllBanksOverview';
import AllBanksChildrenModal from './AllBanksChildrenModal';
function AllBanksOverviewTable({campaignDataRaw, campaignData, menuList, tableRef, broadcastHeaders,metrics ,campaignQuery, selectedChannel, campaignFilter}) {

    const [openMenuIndex, setOpenMenuIndex] = useState(null);
    const {onOpen, isOpen, onClose} = useDisclosure();
    const orgToken = store.getState().auth.selectedOrg.token;
    const toast = useToast();
    useEffect(() => {
        const handleScroll = () => {
            if (openMenuIndex !== null) {
                setOpenMenuIndex(null);
            }
        };
        const tableElement = document.querySelector('.css-1km9ei0')
        if (tableElement) {
            tableElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableElement) {
                tableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [openMenuIndex]);

        const [details,setDetails] = useState();

    const handleMenuOpen = (index) => {
        setOpenMenuIndex(index);
    };
    const handleMenuClose = () => {
        setOpenMenuIndex(null);
    };
    const navigate = useNavigate()
    const actionHandler = {
        delete: async function (data) {
            let resp = await deleteCampaign(data.uuid).catch((err)=>console.log(err))
            if (resp?.status === 200) {
                campaignQuery.refetch()
                toast(
                    {title: 'Campaign deleted successfully.',  status: 'success'}
                    )
            }
        },
        edit: function (data) {
            
            navigate('/all-banks/create' , {
                 state:{
                    data: data?.draft_data,
                    uuid: data?.uuid, 
                    approval_status : data?.approval_status,
                    createdBy : data?.createdBy
                }
                })
        },
        publish: function (data) {

            data = data[0]
            let scheduleData = {
                repeatAfterDays:data.campaignData.repeatAfterDays,
                repeatStopDateTime:data.campaignData.repeatStopDateTime,
                scheduleAt:data.campaignData.scheduleAt,
                scheduleStatus:data.campaignData.scheduleStatus
                }

            publishCampaign(orgToken,data.broadcastId,scheduleData,data.scheduleStatus)
        },
        pause: function (data) {
            data = data[0]
            let scheduleData = {
                repeatAfterDays:data.campaignData.repeatAfterDays,
                repeatStopDateTime:data.campaignData.repeatStopDateTime,
                scheduleAt:data.campaignData.scheduleAt,
                scheduleStatus:data.campaignData.scheduleStatus
                }

            publishCampaign(orgToken,data.broadcastId,scheduleData,"pause")
        },
        resume: function (data) {
            data = data[0]
            let scheduleData = {
                repeatAfterDays:data.campaignData.repeatAfterDays,
                repeatStopDateTime:data.campaignData.repeatStopDateTime,
                scheduleAt:data.campaignData.scheduleAt,
                scheduleStatus:data.campaignData.scheduleStatus
                }

            publishCampaign(orgToken,data.broadcastId,scheduleData,"resume")
        },
        downloadOrders: function (data) {
            console.log("download orders", data)
        },
        downloadCampaignReportNew: ((url) => {
            window.open(url)
        })
    }
    const badgeColor = {
        ERROR: 'red',
        COMPLETED: 'green',
        DRAFT: 'yellow',
        QUEUED : 'cyan'
    }

    return (
        <Tbody overflowY={'auto'} ref={tableRef} >
            {
                !!campaignData?.length && campaignData?.map((dt, i) => (
                    <Tr>
                        <Td w={'25%'} fontSize={'0.8vw'} verticalAlign={'middle'}>
                            <Flex gap={3}>
                                {/* {<Icon as={channels[dt.channel]?.icon} boxSize={5}></Icon>} */}
                                <Box>
                                    <Text>{!!campaignDataRaw[i]?.campaignData && campaignDataRaw[i]['campaignData']?.broadcast=="CONTROL_GROUP" && "C_G " + dt.name || campaignDataRaw[i]?.campaignName }
                                    
                                    </Text>
                                    
                                    {
                                      dt.hasOwnProperty("children") && !_.isEmpty(dt?.children) &&
                                        <Text textDecoration={'underline'} onClick={()=>{
                                            
                                          setDetails(dt.children)
                                            onOpen()
                                        }} cursor={'pointer'} >View Details </Text>
                                    }
                                </Box>
                            </Flex>
                        </Td>
                            <Td w={'6%'} fontSize={'0.8vw'}>
                                <Text fontWeight={'400'}>
                                <Badge colorScheme={badgeColor[_.upperCase(dt.status)]} marginLeft={'10px'} fontWeight={'400'}>
                                    {dt.status}
                                </Badge>
                                </Text>
                            </Td>
                        {
                            <Td w={'6%'} fontSize={'0.8vw'}>
                                
                                <Text color={'light'}>{moment(dt.createdAt).format('LL')} {moment(dt.createdAt).format('LT')} </Text>
                            </Td>
                            }
                            {
                            <Td w={'6%'} fontSize={'0.8vw'}>
                                <Text color={'light'}>{!!dt.audit.length && dt?.audit?.map((au)=>(
                                    <Text>{moment.utc(au.time).local().format('LL')} {moment.utc(au.time).local().format('LT')}
                                    : {au.action_message}
                                  </Text>
                                ))}</Text>
                            </Td>
                            }
                            <Td w={'10%'}>
                            <Menu isOpen={openMenuIndex === i} onClose={handleMenuClose}>
                                <MenuButton onClick={() => handleMenuOpen(i)}>
                                    <Icon as={BsThreeDotsVertical}></Icon>
                                </MenuButton>
                                <MenuList dataValue={i} ref={menuList} >
                                    {
                                        dt.actions.map(ac => (
                                            <MenuItem key={ac} onClick={()=>actionHandler[ac](dt)} >{ac}</MenuItem>
                                        ))
                                    }
                                </MenuList>
                            </Menu>

                        </Td>
                        
                        

                        
                    </Tr>
                ))
            }
            {/* <Tr>
                <Td w={'25%'}>First Campaign</Td>
                <Td w={'6%'}>End of Season Sale</Td>
                <Td w="7%">{JSON.stringify(new Date())}</Td>
                <Td w={'30%'}>
                    <CampaignWisePerformance></CampaignWisePerformance>
                </Td>
                <Td w={'15%'}>
                    Engagement
                </Td>
                <Td w={'7%'}>
                    10
                </Td>
                <Td w={'10%'}>
                    Action
                </Td>
            </Tr> */}
        {/* <CampaignDetailsModal rawData={campaignDataRaw} isOpen={isOpen} onClose={onClose} data={details} /> */}
        <AllBanksChildrenModal campaignFilter={campaignFilter} isOpen={isOpen} onClose={onClose} selectedChannel={selectedChannel} campaignDataRaw={details?.children} campaignQuery={campaignQuery} broadcastHeaders = {broadcastHeaders } tableRef={tableRef} menuList={menuList} campaignData={details} />
        </Tbody>
    )
}

export default AllBanksOverviewTable;
