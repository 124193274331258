import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getSegmentationJson, getTargetList, getAvailableHeaders } from "../../Services/api";
import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  
  useToken,
} from "@chakra-ui/react";
import hexToRgba from 'hex-to-rgba'
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from "@ajna/pagination";
import { Link } from "react-router-dom";

const CustomerOverview = ({ org }) => {
  const [customers, setCustomers] = useState([]);
  const [availableHeaders, setAvailableHeaders] = useState([]);
  const [mainColor] = useToken('colors', ['primary.main'])
  const [tableHeadersForCustomers, setTableHeadersForCustomers] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 1, perPage: 10, totalItems: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0)
  const totalPages = Math.ceil(pagination.totalItems / pagination.perPage);
  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({ total: totalCount, initialState: { currentPage: 1, pageSize: 5, isDisabled: false }, limits: { outer: 1, inner: 1 } })
  useEffect(() => {
    fetchCustomers(pagination.currentPage, pagination.perPage);
  }, [pagination.currentPage, pagination.perPage]);

  useEffect(() => {
    fetchAvailableHeaders();
  }, []);

  useEffect(() => {
    if (customers.length > 0 && availableHeaders.length > 0) {
      const headers = prepareTableHeaders(customers, availableHeaders);
      setTableHeadersForCustomers(headers);
    }
  }, [customers, availableHeaders]);

  const prepareTableHeaders = (customersList, availableHeaders) => {
    if (!customersList || customersList.length === 0 || availableHeaders.length === 0) {
      console.warn("Insufficient data to prepare table headers");
      return [];
    }

    const stockerHeaders = Object.keys(customersList[0]);
    const availableHeaderKeys = availableHeaders.map((header) => header.key);
    const tableHeadersForCustomers = stockerHeaders.filter((header) =>
      availableHeaderKeys.includes(header)
    );

    console.log("Prepared Table Headers:", tableHeadersForCustomers);
    return tableHeadersForCustomers;
  };

  const fetchAvailableHeaders = async () => {
    try {
      const headers = await getAvailableHeaders();
      setAvailableHeaders(headers);
    } catch (error) {
      console.error("Error fetching headers:", error);
    }
  };

  const fetchCustomers = async (page, perPage) => {
    setLoading(true);
    try {
      const segmentationJson = getSegmentationJson();
      const response = await getTargetList(
        org.id,
        "customers",
        page,
        perPage,
        "asc",
        "name",
        false,
        segmentationJson
      );

      const customersData = response.data.data || [];
      setCustomers(customersData);
      setPagination((prev) => ({
        ...prev,
        totalItems: customersData.length > 0 ? customersData[0]._total_count : 0,
      }));
      setTotalCount(customersData[0]?._total_count || 0);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPagination((prev) => ({ ...prev, currentPage: newPage }));
    }
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Text color="red.500" fontSize="lg">
          Error: {error}
        </Text>
      </Flex>
    );
  }

  return (
    <Box p={6}>
      <Heading size="lg" mb={4}>
        Customer Overview
      </Heading>
      
      <Box overflowX="auto" overflowY="auto" maxHeight="700px" borderWidth="1px" borderRadius="md">
        <Table variant="striped" colorScheme="gray" size="sm">
          <Thead>
            <Tr>
              <Th>Details</Th>
              {availableHeaders.map((header, index) => (
                <Th key={index}>{header.label || header}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {customers.map((customer) => (
              <Tr key={customer.id}>
                <Td>
                  
                  <Link
                  
                    to={`/audience/customers/details/${customer.customer_id}`}
                    
                  >
                    <Text
                    color="blue.500"
                    fontWeight="bold"
                    cursor={'pointer'}
                    textDecoration={'underline'}
                    >
                  
                    View Details
                    </Text>
                  </Link>
                </Td>
                {availableHeaders.map((header, index) => (
                  <Td key={index}>{customer[header.key] || "-"}</Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
        <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={setCurrentPage} >
            <PaginationContainer alignItems={'center'} justifyContent="end" mt={2}>
              <PaginationPrevious mr={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&lt;</PaginationPrevious>
              <PaginationPageGroup height={8}>
                {
                  pages.map(page => (
                    <PaginationPage key={`pagination_page_${page}`} page={page} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8} isDisabled={currentPage === page}></PaginationPage>
                  ))
                }
              </PaginationPageGroup>
              <PaginationNext ml={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&gt;</PaginationNext>
            </PaginationContainer>
          </Pagination>
      
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
    org: state.auth.selectedOrg,
  };
};

export default connect(mapStateToProps)(CustomerOverview);
