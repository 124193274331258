
import { HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack, Text, Input, useToast, Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import Select from '../../components/themeComponents/react-chakra-select';
import { TagsInput } from 'react-tag-input-component'
import axiosInstance from '../../Services/axiosInstance'
import { createQueryDataForCampaign, prepareOrgList } from '../../_helpers/data_helper'
import { getOrgList } from '../../Services/api'

export default function DownloadReportModal({ isOpen, onClose, campaignFilter, dateRange, emailOnly , uuid }) {
    const [email, setEmail] = useState("")
    const [error, setError] = useState(false);
    const toast = useToast()
    const [orgList, setOrgList] = useState([]);
    const [selectedOrgList, setSelectedOrgList] = useState([]);
    const validateEmail = (value) => {
        let re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (re.test(value)) {
            setError(false)
            return re.test(value)
        } else {
            setError(true)
            return re.test(value)
        }
    }
    let DownloadReport = async () => {

        let url = "/broadcast/getzipfile/" + btoa(email)
        let res = await axiosInstance.get(url, { params: { ...createQueryDataForCampaign(campaignFilter, dateRange) } }).catch((err) => { toast({ title: 'error', description: 'something went wrong', status: 'error' }); onClose() })
        if (!!res.data) {
            toast({ title: 'Success', description: 'Campaigns report sent successfully', status: 'success' })
            onClose()
        }
    }
    let downloadReportEmail = async () => {

        let url = "/rest/v1/email/report/" + (email) + "?uuid="+ uuid
        let res = await axiosInstance.get(url).catch((err) => { toast({ title: 'error', description: 'something went wrong', status: 'error' }); onClose() })
        if (!!res.data) {
            toast({ title: 'Success', description: 'Campaigns report sent successfully', status: 'success' })
            onClose()
        }
    }
      const fetchOrgList = async () => {
        try {
            
          const orgs = await getOrgList();          
          setOrgList(orgs?.data || []);
        } catch (err) {
          console.error('Error fetching organization list:', err);
        }
      };
    
      
    let handleSelectChange = (selectedValues) => {
        setSelectedOrgList(selectedValues)
    }

    let downloadReportAllBanks = async () => {
        
        let data = {
            orgs: selectedOrgList.map((org) =>org.value),
            email : email
        }
        let url = "/broadcast/v3/send-campaign-report"
        
        let res = await axiosInstance.post(url,data, { params: { ...createQueryDataForCampaign(campaignFilter, dateRange) } }).catch((err) => { toast({ title: 'error', description: 'something went wrong', status: 'error' });
         onClose()
         })
        if (!!res?.data) {
            toast({ title: 'Success', description: 'Campaigns report sent successfully', status: 'success' })
            onClose()
        }
    }
    useEffect(()=>{
        if(window?.location?.href?.includes('all-banks')){

            fetchOrgList()
        }
    },[])
    

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay></ModalOverlay>
            <ModalContent>
                <ModalHeader>Download Report  </ModalHeader>
                <ModalCloseButton></ModalCloseButton>
                <ModalBody>
                    <HStack spacing={4} w={'100%'} >
                        <VStack w={'100%'} >
                            <Text></Text>
                            <Input value={email} placeholder='Enter email' onChange={(e) => setEmail(e.target.value)} onKeyUp={(e) => validateEmail(e.target.value)} />
                        </VStack>
                    </HStack>
                    {
                    !!prepareOrgList(orgList)?.length > 0 &&
                    <Box mt={'10px'} >

                    <Select 
                    
                    isMulti
                    options={prepareOrgList(orgList)}
                    onChange={handleSelectChange}
                    value={selectedOrgList}
                    closeMenuOnSelect={false}
                    colorScheme="brand"
                    isClearable={false}
                    />
                    </Box>
                }
                    {error && <Text size={'sm'} color={'red'} >Enter a valid email</Text>}
                    <PrimaryButton label={'Send'} mt={2} float={'right'} onClick={emailOnly? downloadReportEmail : window.location.href.includes("all-banks") ? downloadReportAllBanks : DownloadReport} />

                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}



