import React, { useState, useEffect, Fragment } from "react";
import Flatpickr from "react-flatpickr";
import {
  Box,
  Flex,
  Stack,
  Text,
  IconButton
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa";
import "flatpickr/dist/themes/material_green.css";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import Select from "../../components/themeComponents/react-chakra-select";
import { getTemplateRatioIncrementer } from "../../_helpers/data_helper";
import * as _ from 'lodash';

function SplitAndSend({
  channel,
  orgToken,
  scheduleData,
  setScheduleData,
  initializeScheduleData,
  targetRatiosError,setTargetRatiosError
}) {


  const onDateSelect = (date, index) => {
    setScheduleData((prev) => {
      const updated = { ...prev };
      updated[orgToken].targetRatios[index].scheduleAt = date[0];
      return updated;
    });
  };

  const handleSliderChange = (index, value) => {
    setScheduleData((prev) => {
      const updated = { ...prev };
      const targetRatios = updated[orgToken]?.targetRatios || [];

      targetRatios[index].ratio = value;
      updated[orgToken].targetRatios = targetRatios;

      const total = targetRatios.reduce((sum, item) => sum + item.ratio, 0);
      if (total !== 100) {
        setTargetRatiosError("The sum of target ratios must be 100.");
      } else {
        setTargetRatiosError("");
      }

      return updated;
    });
  };

  const addNewSlider = () => {
    setScheduleData((prev) => {
      const updated = { ...prev };
      const targetRatios = updated[orgToken]?.targetRatios || [];

      if (targetRatios.length >= 6) {
        return updated;
      }

      targetRatios.push({ ratio: 0, scheduleAt: null });
      updated[orgToken].targetRatios = targetRatios;

      return updated;
    });
  };

  const removeSlider = (index) => {
    setScheduleData((prev) => {
      const updated = { ...prev };
      const targetRatios = updated[orgToken]?.targetRatios || [];

      targetRatios.splice(index, 1);
      updated[orgToken].targetRatios = targetRatios;

      const total = targetRatios.reduce((sum, item) => sum + item.ratio, 0);
      if (total !== 100) {
        setTargetRatiosError("The sum of target ratios must be 100.");
      } else {
        setTargetRatiosError("");
      }

      return updated;
    });
  };

  useEffect(() => {
    if (!scheduleData[orgToken]?.targetRatios) {
      setScheduleData((prev) => ({
        ...prev,
        [orgToken]: {
          ...prev[orgToken],
          targetRatios: [{ ratio: 100, scheduleAt: null }],
        },
      }));
    }
  }, []);

  const targetRatios = scheduleData[orgToken]?.targetRatios || [];

  return (
    <Fragment>
      <Box
        w={"100%"}
        m={"0 auto"}
        mt={5}
        p={3}
        bg={"white"}
        maxW={"930px"}
        id={"scheduleCampaign"}
      >
        <Flex direction={"column"} mt={4}>
          <Stack spacing={4} direction={"column"}>
            {(
              <Box>
                <Text fontWeight={"bold"}>Adjust Target Ratios</Text>
                {targetRatios.map((item, index) => (
                  <Box key={index} mb={4} display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Text display={'flex'} gap={'10px'} alignItems={'center'}>
                        Target Ratio:
                        <Select
                          options={getTemplateRatioIncrementer()}
                          value={_.filter(getTemplateRatioIncrementer(), { value: item.ratio })}
                          onChange={(value) => handleSliderChange(index, value.value)}
                        />
                        %
                      </Text>
                      <Flex alignItems="center" mt={2}>
                        <Text>Specify Date:</Text>
                        <Box
                          border="1px solid"
                          height={10}
                          fontSize="md"
                          ml={3}
                          p={2}
                          display="flex"
                          alignItems="center"
                          maxWidth="300px"
                          w="100%"
                          rounded="md"
                          borderColor="gray.200"
                        >
                          <Flatpickr
                            options={{
                              minDate: "today",
                              enableTime: true,
                              dateFormat: "M d Y, h:i K",
                            }}
                            value={item.scheduleAt}
                            onChange={(date) => onDateSelect(date, index)}
                          />
                        </Box>
                      </Flex>
                    </Box>
                    <IconButton
                      aria-label="Remove Target Ratio"
                      icon={<FaTrash />}
                      colorScheme="red"
                      onClick={() => removeSlider(index)}
                    />
                  </Box>
                ))}
                {targetRatiosError && <Text color="red.500" mt={2}>{targetRatiosError}</Text>}
                <PrimaryButton
                  label={'Add New Ratio'}
                  mt={4}
                  colorScheme="blue"
                  onClick={addNewSlider}
                />
              </Box>
            )}
          </Stack>
        </Flex>
      </Box>
    </Fragment>
  );
}

export default SplitAndSend;
