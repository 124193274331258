import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import BlockCodes from './BlockCodes';
import {
  Box,
  Select,
  Input,
  Checkbox,
  FormControl,
  FormLabel,
  Stack,
  Text,
  Button,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { saveOrUpdateBlock, getBlockMarkup, getAIBlockFormElements } from '../../Services/api';

const THEMES = [
  { label: 'Theme 1', value: 'theme1' },
  { label: 'Theme 2', value: 'theme2' },
  { label: 'Theme 3', value: 'theme3' },
  { label: 'Theme 4', value: 'theme4' },
  { label: 'Theme 5', value: 'theme5' },
  { label: 'Theme 6', value: 'theme6' },
  { label: 'Theme 7', value: 'theme7' },
  { label: 'Theme 8', value: 'theme8' },
  { label: 'Theme 9', value: 'theme9' },
];

const CreateBlock = () => {
  const blockUuidInUrl = window.location.pathname.split("/")[3];
  const navigate = useNavigate();

  const [blockType, setBlockType] = useState('');
  const [blockUuid, setBlockUuid] = useState(blockUuidInUrl || '');
  const [formElements, setFormElements] = useState([]);
  const [formData, setFormData] = useState({});
  const [htmlContent, setHtmlContent] = useState('');
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingHtml, setLoadingHtml] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blockName, setBlockName] = useState('');
  const [blockDescription, setBlockDescription] = useState('');
  const [loadingEmbedCodes, setLoadingEmbedCodes] = useState(false);

  useEffect(() => {
    if (blockUuidInUrl) {
      setBlockUuid(blockUuidInUrl);
    }
  }, [blockUuidInUrl]);

  useEffect(() => {
    const fetchFormElements = async () => {
      if (!blockType && !blockUuid) return;
      setLoadingForm(true);
      try {
        const response = await getAIBlockFormElements(blockType, blockUuid);
        const defaultValues = {};
        response.data.values.forEach((field) => {
          defaultValues[field.name] = field.defaultValue || '';
        });
        setBlockType(response.data.channel || '')
        setBlockName(response.data.name || '')
        setBlockDescription(response.data.description || '')
        setFormElements(response.data.values);
        setFormData(defaultValues);
      } catch (error) {
        console.error('Error fetching form elements:', error);
      } finally {
        setLoadingForm(false);
      }
    };

    fetchFormElements();
  }, [blockType]);

  useEffect(() => {
    const fetchHtmlContent = async () => {
      if ((!blockType || Object.keys(formData).length === 0) && !blockUuid) return;
      setLoadingHtml(true);
      try {
        const response = await getBlockMarkup(blockType, blockUuid, formData);
        setHtmlContent(response.data.html || '');
      } catch (error) {
        console.error('Error fetching HTML content:', error);
        setHtmlContent('<p>Error rendering content</p>');
      } finally {
        setLoadingHtml(false);
      }
    };

    fetchHtmlContent();
  }, [formData, blockType]);

  const fetchEmbedCodes = async () => {
    setLoadingEmbedCodes(true);
    try {
      const payload = {
        ...formData,
        name: blockName,
        description: blockDescription,
      };
      const response = await saveOrUpdateBlock(blockType, blockUuid, payload);
      setBlockUuid(response.data.uuid);

      if (response.data.uuid) {
        navigate(`/ai-block/codes/${response.data.uuid}`, { state: { apiResponse: response.data } });
      } else {
        console.error('Error saving block:', response);
      }
    } catch (error) {
      console.error('Error saving block:', error);
    } finally {
      setLoadingEmbedCodes(false);
    }
  };

  const handleGetCodeClick = () => {
    setIsModalOpen(true);
  };

  const handleModalSubmit = () => {
    if (!blockName || !blockDescription) {
      alert('Please provide a name and description for the block.');
      return;
    }
    fetchEmbedCodes();
    setIsModalOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <Box display="flex" p={5} gap={5}>
      {/* Form Section */}
      <Box flex="1" p={4} borderWidth={1} borderRadius="lg" shadow="md">
        <FormControl mb={4}>
          <FormLabel>Block Type</FormLabel>
          <Select
            placeholder="Select Block Type"
            value={blockType}
            onChange={(e) => setBlockType(e.target.value)}
          >
            <option value="addedtocart">Added to Cart</option>
            <option value="recentlybrowsed">Recently Browsed</option>
            <option value="recommended">Recommended</option>
            <option value="bought">Bought</option>
          </Select>
        </FormControl>

        {loadingForm ? (
          <Spinner mt={4} />
        ) : (
          <Stack spacing={2}>
            {formElements.map((field) => (
              <FormControl key={field.name} mb={2}>
                <FormLabel>{field.label}</FormLabel>
                {field.type === 'text' && (
                  <Input
                    type="text"
                    name={field.name}
                    value={formData[field.name] || ''}
                    onChange={handleFormChange}
                    size="sm"
                  />
                )}
                {field.type === 'number' && (
                  <Input
                    type="number"
                    name={field.name}
                    value={formData[field.name] || ''}
                    onChange={handleFormChange}
                    size="sm"
                  />
                )}
                {field.type === 'color' && (
                  <Input
                    type="color"
                    name={field.name}
                    value={formData[field.name] || '#000000'}
                    onChange={handleFormChange}
                    size="sm"
                  />
                )}
                {field.type === 'checkbox' && (
                  <Checkbox
                    name={field.name}
                    isChecked={formData[field.name] || false}
                    onChange={handleFormChange}
                  >
                    {field.label}
                  </Checkbox>
                )}
                {field.type === 'theme' && (
                  <Select
                    name={field.name}
                    value={formData[field.name] || ''}
                    onChange={handleFormChange}
                    placeholder="Select a Theme"
                    size="sm"
                  >
                    {THEMES.map((theme) => (
                      <option key={theme.value} value={theme.value}>
                        {theme.label}
                      </option>
                    ))}
                  </Select>
                )}
              </FormControl>
            ))}
          </Stack>
        )}
        <Button colorScheme="blue" mt={4} onClick={handleGetCodeClick}>
          Get Code
        </Button>
      </Box>

      {/* HTML Content Section */}
      <Box flex="2" p={4} borderWidth={1} borderRadius="lg" shadow="md">
        <Text mb={4} fontWeight="bold">
          Rendered Content
        </Text>
        {loadingHtml ? (
          <Spinner />
        ) : (
          <Box
            border="1px solid #ddd"
            p={4}
            minHeight="200px"
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          ></Box>
        )}
      </Box>

      {/* Modal Section */}
      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Provide Block Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Block Name</FormLabel>
              <Input
                placeholder="Enter block name"
                value={blockName}
                onChange={(e) => setBlockName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Block Description</FormLabel>
              <Input
                placeholder="Enter block description"
                value={blockDescription}
                onChange={(e) => setBlockDescription(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleModalSubmit}>
              Submit
            </Button>
            <Button variant="ghost" onClick={handleModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreateBlock;
