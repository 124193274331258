import React, { Fragment } from 'react'
import CampaignTable from '../Campaigns/components/CampaignTable'
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, VStack, TableContainer, Table, Flex, } from '@chakra-ui/react'
import TableHead from '../Campaigns/components/TableHead'
import { OvalSpinner } from '../../components/Loaders'

export default function AllBanksChildrenModal({ isOpen, onClose, campaignFilter,campaignDataRaw, campaignData, menuList, tableRef, broadcastHeaders, metrics, campaignQuery, selectedChannel }) {

    return (
        <>
            <Modal isOpen={isOpen} size={'full'} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Details
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pt={0}>
                    <TableContainer bg={'white'} maxH={'60vh'} w={'99%'} overflowY={'auto'} mt={0} >
        {
          campaignQuery?.isLoading && <Flex justifyContent={'center'} alignItems={'center'} h={'200px'}><OvalSpinner width={50} height={50}></OvalSpinner></Flex>
        }
        <Table >
          {
            !!campaignData?.length>0?(<>
            <Fragment>
              <TableHead headersProps={ Object.keys(broadcastHeaders).includes("All") ? ['Campaign Name', 'Status', 'Sent At', 'Performance', 'Enagement', 'Revenue', 'Action'] : [...broadcastHeaders[(('Email') )],"Bank Name"].filter(item=>item!='Action')}></TableHead>
              <CampaignTable selectedChannel={selectedChannel} campaignDataRaw={campaignData} campaignData={campaignData} campaignQuery={campaignQuery} broadcastHeaders={broadcastHeaders} tableRef={tableRef} menuList={menuList} />
            </Fragment></>):(<><Text>No campaigns are created</Text></>)   

          }
        </Table>

      </TableContainer>
                       
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </>
    )
}
