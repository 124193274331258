import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Switch,
  Radio,
  RadioGroup,
  Input,
  Button,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../Services/axiosInstance";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";

const EditableCard = ({ initialData }) => {
  const [data, setData] = useState(initialData);
  const toast = useToast();

  const handleSwitchChange = (category, value) => {
    setData((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        useDefaultRate: value,
      },
    }));
  };

  const handleInputChange = (category, field, value) => {
    setData((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      await axiosInstance.post("/su/wallet/fororg/H65F_CU9SDWfdnCniIZgDg/INR", data);
      toast({
        title: "Success",
        description: "Data has been successfully updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to save data.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  return (
    <Box p={4}>
      <Heading mb={5} size="md">
        Rate Card
      </Heading>

      {data && (
        <Accordion allowMultiple>
          {Object.entries(data).map(([category, fields]) => (
            <AccordionItem key={category}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Heading size="sm">{category.replace(/_/g, " ")
                      .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase())}</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack direction="row" alignItems="center" mb={4}>
                  <Text>Default wallet rate</Text>
                  <Switch
                    isChecked={fields.useDefaultRate}
                    onChange={(e) => handleSwitchChange(category, e.target.checked)}
                  />
                </Stack>
                <Stack spacing={4}>
                  {Object.entries(fields).map(
                    ([field, value]) =>
                      field !== "useDefaultRate" && (
                        <Box key={field}>
                          <Text mb={1} fontWeight="bold">
                            {field.replace(/([A-Z])/g, " $1").replace(/_/g, " ")
                              .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase())}
                          </Text>
                          {field === "type" ? (
                            <RadioGroup
                              value={value}
                              onChange={(e) => handleInputChange(category, field, e)}
                            >
                              <Stack direction="row">
                                <Radio value="All">All</Radio>
                                <Radio value="Transactional">Transactional</Radio>
                                <Radio value="Promotional">Promotional</Radio>
                              </Stack>
                            </RadioGroup>
                          ) : (
                            <Input
                              size="sm"
                              value={value}
                              onChange={(e) =>
                                handleInputChange(category, field, e.target.value)
                              }
                            />
                          )}
                        </Box>
                      )
                  )}
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}
      <PrimaryButton label={'Save Changes'} mt={4} float={'right'} onClick={handleSubmit}>

      </PrimaryButton>
    </Box>
  );
};

export default function RateCard() {
  const [initialData, setInitialData] = useState({});
  let { orgToken } = useParams();

  const fetchRateCard = async () => {
    let url = `/su/wallet/fororg/${orgToken}/INR`;
    let response = await axiosInstance.get(url);
    setInitialData(response.data);
  };

  useEffect(() => {
    fetchRateCard();
  }, []);

  return <EditableCard initialData={initialData} />;
}
