import React, { Fragment, useEffect } from 'react';
import { Box, Flex, FormLabel, Radio, Stack, Text, Input, Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import { Formik } from 'formik';
import { mapKeys } from 'lodash';

function ScheduleCampaign({ channel, formik, errors }) {
    const minDate = new Date();

    const onDateSelect = (dateStr, formikProp) => {
        formik.setFieldValue(formikProp, dateStr[0]);
    };
    useEffect(()=>{
        if(!!formik?.values?.targetedAudience?.includes[0] && !!formik.values.scheduleData.targetRatios.length && !!formik?.values?.scheduleData?.targetRatios[0]?.templateData?.name ){

                formik.setFieldValue("campaignType", "multiple_schedule_v2");
                formik.setFieldValue("broadcastType", "multiple_schedule_v2");
                formik.setFieldValue("broadcast", "multiple_schedule_v2");
            }
    },[])
    
    return (
        <Fragment>
            <Box w={'100%'} m={'0 auto'} mt={5} p={3} bg={'white'} maxW={'930px'} id={'scheduleCampaign'}>
                <Text color={'heading'} as={'b'}>Schedule Campaign </Text>
                <Flex direction={'column'} mt={4}>
                    { !!formik?.values?.targetedAudience?.includes[0] && !!formik.values.scheduleData.targetRatios.length && !!formik?.values?.scheduleData?.targetRatios[0]?.templateData?.name? (
                        !!formik?.values?.targetedAudience?.includes[0] && formik.values?.scheduleData?.targetRatios.map((tempKey, index) => (
                            <>
                            {
                                    formik.values.targetedAudience.includes[0][`targetRatio${index+1}`] !== 0 &&
                                <Box key={index} mt={4}>
                                
                                <Radio value='SCHEDULED' name='scheduleData.scheduleStatus' onChange={formik.handleChange} isChecked={formik.values.scheduleData.scheduleStatus === 'SCHEDULED'}>
                                <FormLabel color={'inputLabel'} m={0} fontSize={'sm'}>Schedule for Later</FormLabel>
                                {/* {JSON.stringify(tempKey)} */}
                                <Text>For "{tempKey?.ratio}%" Audience </Text>
                                
                                </Radio>
                                <Flex alignItems={'center'} pl={10}>
                                <Text color={'inputLabel'} fontSize={'sm'}>Specify</Text>
                                    <Box border={'1px solid'} height={10} fontSize={'md'} paddingInlineStart={4} paddingInlineEnd={4} display={'flex'} alignItems={'center'} maxWidth={'300px'} w={'100%'} ml={3} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                        <Flatpickr
                                            options={{ minDate: 'today', enableTime: true, dateFormat: 'M d Y, h:i K' }}
                                            value={formik.values.scheduleData.targetRatios[index].scheduleAt ? new Date(formik.values.scheduleData.targetRatios[index].scheduleAt) : null}
                                            onChange={(props) => {
                                                onDateSelect(props, `scheduleData.targetRatios[${index}].scheduleAt`)
                                                formik.setFieldValue("scheduleData.scheduleStatus", `SCHEDULED`)
                                            }
                                        }
                                        />
                                        </Box>
                                        </Flex>
                                        </Box>

}
</>
                             
                                    ))
                    ) : (
                        <>
                            <Stack spacing={4} direction={'column'}>
                                <Radio value='NOW' name='scheduleData.scheduleStatus' onChange={formik.handleChange} isChecked={formik.values.scheduleData.scheduleStatus === 'NOW'}>
                                    <FormLabel color={'inputLabel'} m={0} fontSize={'sm'}>Send Now</FormLabel>
                                </Radio>
                                <Radio value='SCHEDULED' name='scheduleData.scheduleStatus' onChange={formik.handleChange} isChecked={formik.values.scheduleData.scheduleStatus === 'SCHEDULED'}>
                                    <FormLabel color={'inputLabel'} m={0} fontSize={'sm'}>Schedule for Later</FormLabel>
                                </Radio>
                                {
                                    (formik.values.scheduleData.scheduleStatus === 'SCHEDULED') && (
                                        <Flex alignItems={'center'} pl={10}>
                                            <Text color={'inputLabel'} fontSize={'sm'}>Specify</Text>
                                            <Box border={'1px solid'} height={10} fontSize={'md'} paddingInlineStart={4} paddingInlineEnd={4} display={'flex'} alignItems={'center'} maxWidth={'300px'} w={'100%'} ml={3} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                                <Flatpickr
                                                    options={{ minDate: 'today', enableTime: true, dateFormat: 'M d Y, h:i K' }}
                                                    value={new Date(formik.values.scheduleData.scheduleAt)}
                                                    onChange={(props) => onDateSelect(props, "scheduleData.scheduleAt")}
                                                />
                                            </Box>
                                        </Flex>
                                    )
                                }
                                <Radio value='RECURRING' name='scheduleData.scheduleStatus' onChange={formik.handleChange} isChecked={formik.values.scheduleData.scheduleStatus === 'RECURRING'}>
                                    <FormLabel color={'inputLabel'} m={0} fontSize={'sm'}>Recurring</FormLabel>
                                </Radio>
                                {
                                    (formik.values.scheduleData.scheduleStatus === 'RECURRING') && (
                                        <Box display={'flex'} flexDirection={'column'} gap={3}>
                                            <Flex alignItems={'center'} pl={10}>
                                                <Text color={'inputLabel'} fontSize={'sm'} maxWidth={'100px'} w={'100%'}>Start At</Text>
                                                <Box border={'1px solid'} height={10} fontSize={'md'} paddingInlineStart={4} paddingInlineEnd={4} display={'flex'} alignItems={'center'} maxWidth={'300px'} w={'100%'} ml={3} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                                    <Flatpickr
                                                        options={{ minDate: 'today', enableTime: true, dateFormat: 'M d Y, h:i K' }}
                                                        value={new Date(formik.values.scheduleData.startAt)}
                                                        onChange={(props) => onDateSelect(props, "scheduleData.startAt")}
                                                    />
                                                </Box>
                                            </Flex>
                                            <Flex alignItems={'center'} pl={10}>
                                                <Text color={'inputLabel'} fontSize={'sm'} maxWidth={'100px'} w={'100%'}>Repeat Every</Text>
                                                <Input {...formik.getFieldProps("scheduleData.repeatAfterDays")} ml={3} maxW={'300px'} />
                                            </Flex>
                                            <Flex alignItems={'center'} pl={10}>
                                                <Text color={'inputLabel'} fontSize={'sm'} maxWidth={'100px'} w={'100%'}>Stop At</Text>
                                                <input type='hidden' {...formik.getFieldProps("scheduleData.repeatStopDateTime")} required />
                                                <Box border={'1px solid'} height={10} fontSize={'md'} paddingInlineStart={4} paddingInlineEnd={4} display={'flex'} alignItems={'center'} maxWidth={'300px'} w={'100%'} ml={3} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                                    <Flatpickr
                                                        options={{ minDate: 'today', enableTime: true, dateFormat: 'M d Y, h:i K' }}
                                                        value={new Date(formik.values.scheduleData.repeatStopDateTime)}
                                                        onChange={(props) => onDateSelect(props, "scheduleData.repeatStopDateTime")}
                                                    />
                                                </Box>
                                            </Flex>
                                        </Box>
                                    )
                                }
                                <Flex direction={'column'} ml={10} w={'70%'}>
                                    {
                                        !!errors.length && errors.map((error, errorIndex) => (
                                            <Alert status="error" key={errorIndex}>
                                                <Fragment>
                                                    <AlertIcon />
                                                    <AlertTitle>{error}</AlertTitle>
                                                </Fragment>
                                            </Alert>
                                        ))
                                    }
                                </Flex>
                            </Stack>
                        </>
                    )}
                </Flex>
            </Box>
        </Fragment>
    );
}

export default ScheduleCampaign;
