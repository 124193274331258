import React, { useState, useEffect, useRef } from 'react';
import { Box, Textarea, VStack, Text, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, SliderTrack, SliderFilledTrack, Tooltip, SliderThumb, FormLabel } from '@chakra-ui/react';
import hljs from 'highlight.js/lib/core';
import sql from 'highlight.js/lib/languages/sql';
import 'highlight.js/styles/github.css';
import Slider from 'react-slick';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import * as _ from 'lodash';
import { getCountsFromQuery, getCountsFromQueryMulti } from '../../Services/api';
import store from '../../store/store';

hljs.registerLanguage('sql', sql);

const CodeEditor = ({ sendQuery, orgId, formik, audienceRange, setAudienceRange }) => {
  const [query, setQuery] = useState('');
  const textareaRef = useRef(null);
  const highlightedRef = useRef(null);
  const [tooltipVisibility, setTooltipVisibility] = useState(false)
  const [countsFromQuery, setCountsFromQuery] = useState([])
  const [countsFromQuerySingle, setCountsFromQuerySingle] = useState()
  const [isLoading, setLoading] = useState(false);
  
  const getQueryCounts = async () => {
    let obj = {}
    let res = {}
    if(window.location.href.includes('all-banks')){
       res = await getCountsFromQueryMulti(formik.values.orgList, query).catch(err => {
      }
    )  
    if(res?.data){
      setCountsFromQuery(res?.data?.data);
    }
    }else{
      res = await getCountsFromQuery(orgId, query).catch(err => {})
      !window.location.href.includes('all-banks') && setCountsFromQuerySingle(res?.data?.data?.counts)
    }
    
    console.log(countsFromQuery)
    setLoading(false);
  }
  let handleAudienceRange = (v) => {
    setAudienceRange(v)
  }

  const handleQueryChange = (e) => {

    setQuery(e.target.value);
    sendQuery(e.target.value)
  };
  const orgList = store.getState().org.orgList
  const getOrgName = (token) => {

    let orgName = orgList.filter((e) => e.token == token)
    return orgName[0]?.name || ''
  }

  const handleScroll = () => {
    if (highlightedRef.current && textareaRef.current) {

      highlightedRef.current.scrollTop = textareaRef.current.scrollTop;
      highlightedRef.current.scrollLeft = textareaRef.current.scrollLeft;
    }
  };

  useEffect(() => {
    if (highlightedRef.current) {
      const highlighted = hljs.highlight(query, { language: 'sql' }).value;
      highlightedRef.current.innerHTML = highlighted;
    }
  }, [query]);

  return (
    <VStack spacing={4} align="stretch" width="100%">
      <Text>
        Enter a raw query
      </Text>
      <Box
        position="relative"
        width="100%"
        height="150px"
        borderWidth="1px"
        borderRadius="md"
        overflow="hidden"
        backgroundColor="white"
      >

        <Box
          ref={highlightedRef}
          as="pre"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          padding="12px"
          pointerEvents="none"
          whiteSpace="pre-wrap"
          wordWrap="break-word"
          overflow="auto"
          style={{
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
            color: 'black',
            zIndex: 0,
            margin: 0,
          }}
        />


        <Textarea
          ref={textareaRef}
          value={query}
          onChange={handleQueryChange}
          onScroll={handleScroll} 
          placeholder="Type your query here..."
          size="md"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          padding="12px"
          borderWidth="0"
          backgroundColor="transparent"
          zIndex="1"
          resize="none"
          style={{
            caretColor: 'black', // Show the caret in the textarea
            color: 'transparent', // Hide the text in the textarea
            whiteSpace: 'pre-wrap',
            overflow: 'hidden', // Disable scrolling on textarea
            outline: 'none', // Remove default textarea outline
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
          }}
        />
      </Box>
      <PrimaryButton label={'Get Counts'} onClick={getQueryCounts} isLoading={isLoading} />
      {/* <Heading>
            {
              "Total Reach" + " " + countsFromQuery
            }
          </Heading> */}
          
          
          {
           !window.location.href.includes('all-banks') && !!countsFromQuerySingle ? 
            <Text>

              Count : {countsFromQuerySingle}
            </Text>
          :
        !_.isEmpty(countsFromQuery) ?
          (<>
            <Text mt={'2rem'} >
              {
               !!(countsFromQuery).length && (countsFromQuery).map((dt,i) => (
                  <Text>
                    {dt?.org_name} : {dt?.result?.data?.counts || 0}
                  </Text>
                ))

              }
              {/* Reach for segment is {audienceRange}% of {JSON.stringify(countsFromQuery)} */}
            </Text>
            <FormLabel>
              Select Audience for segment 
            </FormLabel>
            <NumberInput maxW='100px' mr='2rem' value={audienceRange} onChange={(e) => setAudienceRange(e)
            }>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {/* <Slider aria-label='slider-ex- 1' value={0}
                  onMouseEnter={() => setTooltipVisibility(true)} onMouseLeave={() => setTooltipVisibility(false)}
                  onChange={(v) => handleAudienceRange(v)} >
                  <SliderTrack>
                    <SliderFilledTrack />
                    <Tooltip
                      hasArrow
                      bg='teal.500'
                      color='white'
                      placement='top'
                      isOpen={tooltipVisibility}
                      label={audienceRange + "%"}
                    >
                      <SliderThumb children={audienceRange} />
                    </Tooltip>
                  </SliderTrack>
                  <SliderThumb />
                </Slider> */}
          </>) :
          (<>
            <div>
              <Text color="red.500">No Counts Avaliable For This Segment</Text>
            </div>
          </>)
      }
    </VStack>
  );
};

export default CodeEditor;
