import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCustomerDetails } from '../../Services/api';
import './CustomerDetails.css';
import { connect } from 'react-redux';
import { Flex } from '@chakra-ui/react';
import { OvalSpinner } from '../../components/Loaders';

const CustomerDetails = ({ org }) => {
  const { customerId } = useParams();
  const [customerDetails, setCustomerDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const details = await getCustomerDetails(org.token, customerId);
        setCustomerDetails(details.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchCustomerDetails();
  }, [org.token, customerId]);

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <OvalSpinner width={60} height={60} />
      </Flex>
    );
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="customer-details-container">
      {/* Header Section */}
      <div className="customer-header">
        <img src={customerDetails.image} alt="Customer" />
        <div className="customer-info">
          <h2>{`${customerDetails.first_name} ${customerDetails.last_name}`}</h2>
          <p>Email: {customerDetails.email}</p>
          <p>Phone: {customerDetails.phone || 'N/A'}</p>
          <p>Location: {`${customerDetails.city}, ${customerDetails.state}, ${customerDetails.country}`}</p>
          <p>Postal Code: {customerDetails.postal_code}</p>
        </div>
      </div>

      {/* Statistics Section */}
      <div className="customer-stats">
        <div>
          <h3>Orders</h3>
          <p>{customerDetails.num_orders || 0}</p>
        </div>
        <div>
          <h3>Total Revenue</h3>
          <p>
            {customerDetails.currency} {customerDetails.revenue || 0}
          </p>
        </div>
        <div>
          <h3>Average Order Value</h3>
          <p>
            {customerDetails.currency} {customerDetails.average_order_value || 0}
          </p>
        </div>
        <div>
          <h3>Gross Profit</h3>
          <p>
            {customerDetails.currency} {customerDetails.gross_profit || 0}
          </p>
        </div>
        <div>
          <h3>Gross Margin</h3>
          <p>{customerDetails.gross_margin || 0}%</p>
        </div>
        <div>
          <h3>Relationship Length</h3>
          <p>{customerDetails.relationship_length || 0} days</p>
        </div>
      </div>

      {/* Timeline Section */}
      <div className="customer-timeline">
        <h3>Customer Timeline</h3>
        <div className="timeline">
          {customerDetails.journey.map((event, index) => (
            <div key={index} className="timeline-item">
              <div className="timeline-icon">
                <img
                  src={event.product_image || '/assets/img/no-image.png'}
                  alt="Product"
                  className="timeline-image"
                />
              </div>
              <div className="timeline-content">
                <h4>{event.product_title}</h4>
                <p>
                  Price: {customerDetails.currency} {event.price}
                </p>
                <p>Quantity: {event.quantity}</p>
                <p>Ordered On: {new Date(event.created_at).toLocaleString()}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Additional Details Section */}
      <div className="additional-details">
        <h3>Additional Details</h3>
        <div className="additional-details-grid">
          <div className="additional-details-item">
            <strong>RFM Segment:</strong> {customerDetails.rfm_segment}
          </div>
          <div className="additional-details-item">
            <strong>F-Score:</strong> {customerDetails.f_score}
          </div>
          <div className="additional-details-item">
            <strong>M-Score:</strong> {customerDetails.m_score}
          </div>
          <div className="additional-details-item">
            <strong>R-Score:</strong> {customerDetails.r_score}
          </div>
          <div className="additional-details-item">
            <strong>Last Email Sent:</strong> {customerDetails.last_email_sent || 'N/A'}
          </div>
          <div className="additional-details-item">
            <strong>Last SMS Sent:</strong> {customerDetails.last_sms_sent || 'N/A'}
          </div>
          <div className="additional-details-item">
            <strong>Status:</strong> {customerDetails.status}
          </div>
          <div className="additional-details-item">
            <strong>Tags:</strong> {customerDetails.tags || 'N/A'}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
    org: state.auth.selectedOrg,
  };
};

export default connect(mapStateToProps)(CustomerDetails);
